import React, { useEffect } from 'react';
import MaterialTable from 'material-table';

export default function ManageHome() {
    const [state, setState] = React.useState({
        columns: [
            { title: 'HomeType', field: 'homeType', lookup: { 'Primary': 'Primary', 'Secondary': 'Secondary' }, },
            { title: 'PropertyType', field: 'propertyType', lookup: { 'House': 'House', 'Condo': 'Condo', 'Townhouse': 'Townhouse', 'Multi-Family': 'Multi-Family', 'Others': 'Others' }, },
            { title: 'Address1', field: 'address1' },
            { title: 'Address2', field: 'address2' },
            { title: 'City', field: 'city' },
            { title: 'State', field: 'state' },
            { title: 'Zip', field: 'zip'},
            { title: 'Country', field: 'country'}
        ]
    });

    const[homeData, setHomeData] = React.useState({
        data: []
    })

    useEffect(() => {
        console.log('ManageTheHome : useEffect() : start');

        let bearerToken = 'Bearer ' + localStorage.getItem('token');
        
        fetch(process.env.REACT_APP_API_URL+'/home/'+localStorage.getItem('userId'), 
            {
                method : 'GET',
                headers : { 
                            'Authorization' : bearerToken
                        }
            })
        .then(res => res.json())
        .then(response => {
            console.log(response);
            setHomeData(
                {
                    data:response
                }
            );
          })
          .catch(error => console.log(error));
      }, [state]);

    return (
        <MaterialTable
            title="Manage Home"
            localization={{
                body: {
                    emptyDataSourceMessage: <h5> Add Home </h5>,
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    backgroundColor: '#0048BA',
                    color: '#FFF',
                    padding : '5px'
                }
            }}
            columns={state.columns}
            data={homeData.data}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        resolve();
                        console.log('newData');
                        console.log(newData);
                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/home', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                newData.memberId = response.memberId;
                                setHomeData((prevState) => {
                                    const data = [...prevState.data];
                                    data.push(newData);
                                    return { ...prevState, data };
                                });
                            });
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        console.log('newData');
                        console.log(newData);
                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/home', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setHomeData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('home be deleted :'+oldData.homeId);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/home/'+oldData.homeId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setHomeData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                    }
                }
            }}
        />
    );
}
