import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { capitalize, CardContent, Fab, Grid } from '@material-ui/core';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import imageCompression from 'browser-image-compression';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input'

export default function ManageFamily(props) {

    const [isLoading, setIsLoading] = React.useState(false);

    const [profileImage, setProfileImage] = React.useState({
        data : ''});

    const [isPossible, setIsPossible] = React.useState(false);

    const [state, setState] = React.useState({
        columns: [
            {   title: 'Relationship', 
                field: 'relationship', 
                lookup: { 'Self' : 'Self', 'Spouse': 'Spouse', 'Son': 'Son', 'Daugther': 'Daugther', 'Father': 'Father', 'Mother': 'Mother' }
            },
            {   title: 'Avatar', 
                field: 'imageUrl', 
                editable: 'never',
                render: rowData => <div> 
                                        <React.Fragment>
                                            <CardContent>
                                            <Grid container>
                                                <input
                                                accept="image/*"
                                                style={{display:'none'}}
                                                id={rowData ? rowData.memberId : 0}
                                                multiple
                                                type="file"
                                                onChange={(evt) => handleChange(evt, rowData)}
                                                />
                                                <label htmlFor={rowData ? rowData.memberId : 0}>
                                                {rowData && rowData.image ? 
                                                    <img src={'data:image/jpeg;base64,'+rowData.image} style={{width: 54, borderRadius: '50%'}} alt="" />
                                                :
                                                <Fab component="span" >
                                                    <AddPhotoAlternateIcon />
                                                </Fab>
                                                }
                                                </label>
                                            </Grid>
                                            </CardContent>
                                        </React.Fragment>
                                    </div>
            },
            {   title: 'First Name', 
                field: 'firstName', 
            },
            {   title: 'Last Name', 
                field: 'lastName', 
            },
            {   title: 'Nick Name', 
                field: 'nickName' 
            },
            {   title: 'Birth Date', 
                field: 'dob', 
                type: 'date', 
            },
            {
                title: 'Gender',
                field: 'gender',
                lookup: { 'Male': 'Male', 'Female': 'Female' }
            },
            {   title: 'Email', 
                field: 'email' 
            }, 
            {   title: 'Phone', 
                field: 'phone',
                editComponent: rowData => <div>
                                        <Input
                                            defaultCountry="US"
                                            country="US"
                                            style={{borderColor: isPossible ? 'red' : 'black', borderWidth: isPossible ? 'medium' : ''}}
                                            placeholder="Enter phone number"
                                            value={ rowData.phone }
                                            onChange = { (evt) => onPhoneChange(evt, rowData)}
                                            />
                                        </div>
            },
        ]
    });

    const[familyData, setFamilyData] = React.useState({
        data:[]
    });

    const onPhoneChange = async (newValue, rowData) => {
        console.log('Phone Number Changed to:'+newValue);        
        let isPhonePossible = isPossiblePhoneNumber(newValue);
        console.log('isPossiblePhoneNumber ? ==>'+isPhonePossible);
        setIsPossible(isPhonePossible);
        console.log('isPossible new state ? ==>'+isPossible);
            //if(isPossible) {
                console.log('Hurrraaaah, it is possible');
                setFamilyData((prevState) => {
                    let newData = rowData;
                    newData.rowData.phone = newValue;
                    const data = [...prevState.data];
                    data[data.indexOf(rowData)] = newData;
                    return { ...prevState, data };
                });
        //}
    };

    // const onPhoneBlur = (newValue, rowData) => {
    //     console.log('Phone Number Blur to:'+newValue.target.value);   
    //     if(newValue && newValue.target.value) {
    //         let isPossible = isPossiblePhoneNumber(newValue.target.value);
    //         console.log('isPossiblePhoneNumber ? ==>'+isPossible);
    //         if(isPossible) {
    //             setFamilyData((prevState) => {
    //                 let newData = rowData;
    //                 newData.rowData.phone = newValue.target.value;
    //                 const data = [...prevState.data];
    //                 data[data.indexOf(rowData)] = newData;
    //                 return { ...prevState, data };
    //             });
    //         }
    //     }
        
    // };

    useEffect(() => {
        console.log('ManageFamily : useEffect() : start');
        fetch(process.env.REACT_APP_API_URL+'/family/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            console.log(response);
            setFamilyData(
                {
                    data:response
                }
            );
          })
          .catch(error => console.log(error));
      }, [state]);


      const handleChange = async (event, rowData) => {
        console.log('GetStarted:handleChange()');
        let img = new Image();
        let memberId = event.target.id;
        if(event.target.files.length !== 0) {
            let imageFile = event.target.files[0];

            const formData = new FormData();
            formData.append(
                "file", imageFile
            );
            
            img.src = window.URL.createObjectURL(imageFile);
            img.onload = () => {  
                if(img.width < 6000 && img.height < 6000){
                    //compression/resizing logic
                    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
                    const options = {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 1000,
                        useWebWorker: true
                      }

                      imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                            
                            const formData = new FormData();
                            formData.append(
                                "file", compressedFile, imageFile.name
                            );
                            formData.append(
                                "userId", localStorage.getItem('userId')
                            );
                            formData.append(
                                "memberId", memberId
                            );
                            setIsLoading(true);
                                fetch(process.env.REACT_APP_API_URL+'/image/upload', {
                                    method : 'POST',
                                    headers: new Headers({ 
                                                            'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                                                        }),
                                    body : formData
                                })
                                .then(resp => resp.json())
                                .then(respData => {
                                    console.log('Successully uploaded your profile picture!');
                                    setFamilyData((prevState) => {
                                        
                                        console.log(rowData);
                                        let newData = rowData;
                                        newData.image = respData.image;

                                        const data = [...prevState.data];
                                        data[data.indexOf(rowData)] = newData;
                                        return { ...prevState, data };
                                    });
                                    setIsLoading(false);  
                                })
                        })
                    .catch(function (error) {
                        console.log(error.message);
                        setIsLoading(false);
                    });
                } else {
                    alert(`Sorry, The image size is bigger for profile pic. It's 
                           ${img.width} x ${img.height}, please resize or pic another 
                           one with less than 2000 x 2000 size image.`);
                }                
            }
        }  
    };


    return (
        <div>
        <MaterialTable
            title="Manage Family"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Family Members',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF',
                    padding : '5px'
                }
            }}
            columns={state.columns}
            data={familyData.data}
            editable={{
                isEditHidden: rowData => (rowData.relationship === 'Self' && rowData.phone),
                isDeleteHidden: rowData => rowData.relationship === 'Self',
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                            resolve();
                            console.log('newData');
                            console.log(newData);
                            newData.userId = localStorage.getItem('userId');
                            fetch(process.env.REACT_APP_API_URL+'/family', {
                                method: 'POST',
                                body: JSON.stringify(newData),
                                headers : new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type' : 'application/json'
                                })
                            }).then(resp => resp.json())
                                .then(response => {
                                    newData.memberId = response.memberId;
                                    setFamilyData((prevState) => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                });
                    }),
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                    resolve();
                    console.log('newData');
                    console.log(newData);
                    newData.userId = localStorage.getItem('userId');


                    
                    fetch(process.env.REACT_APP_API_URL+'/family', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            setFamilyData((prevState) => {
                                const data = [...prevState.data];
                                data[data.indexOf(oldData)] = newData;
                                return { ...prevState, data };
                            });
                        });
                }),
                onRowDelete: async (oldData) => {
                    console.log('memberId to be deleted :'+oldData.memberId);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/family/'+oldData.memberId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE member response');
                    console.log(response);
                    if (response.ok) {
                            setFamilyData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                    };
                }
            }}
        />
        </div>
    );
}
