import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Wishes() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [state, setState] = React.useState({
        columns: []
    });

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const[wishesData, setWishesData] = React.useState({
        data: []
    });

    useEffect(() => {
        setIsSubmitted(true);
        console.log('Managewishes : useEffect() : start');

        fetch(process.env.REACT_APP_API_URL+'/family/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            var owners = {};
            response.forEach(element => {
                owners[element.memberId] = element.firstName;
                owners[0] = "Family";
                console.log(owners);
            });
            
            setState({
                columns: [
                    { title: 'Category', field: 'category' },
                    { title: 'Member', field: 'memberId', lookup : owners },
                    { title: 'Description', field: 'description' },
                    { title: 'Month', field: 'month', lookup: { 'JAN': 'Jan', 'FEB': 'Feb', 'MAR': 'Mar', 'APR': 'Apr', 'MAY': 'May', 'JUN': 'Jun', 'JUL': 'Jul', 'AUG': 'Aug', 'SEP': 'Sep', 'OCT': 'Oct', 'NOV': 'Nov', 'DEC': 'Dec'},},
                    { title: 'Year', field: 'year',
                        validate: rowData => rowData && rowData.year && (isNaN(rowData.year) || (rowData.year < 2021 || rowData.year > 2300)) ? 
                        {isValid : false, helperText: 'Year should be a number between 2021 and 2300'} : {isValid:true, helperText:''},  },
                    { title: 'Cost', field: 'cost',
                        validate: rowData => rowData && rowData.cost && (isNaN(rowData.cost) || (rowData.cost < 0 || rowData.cost > 10000)) ? 
                        {isValid : false, helperText: 'Cost should be a number between 0 and 10,000'} : {isValid:true, helperText:''},  },
                    { title: 'Status', field: 'status', lookup: { 'PLANNED': 'Planned', 'PENDING': 'Pending', 'COMPLETED': 'Completed' }, }
                ]
            });

                fetch(process.env.REACT_APP_API_URL+'/wish/'+localStorage.getItem('userId'), {
                    method: 'GET',
                    headers : new Headers({
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    })
                })
                .then(res => res.json())
                .then(response => {
                    console.log(response);
                    setWishesData(
                        {
                            data:response
                        }
                    );
                  })
                  .catch(error => console.log(error));
                  setIsSubmitted(false);
            })
          .catch(error => console.log(error));

      }, [userId]);

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsSubmitted(false);
    };


    return (
        <div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
        <MaterialTable
            title="Manage Wishes"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Wishes',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF'
                }
            }}
            columns={state.columns}
            data={wishesData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    setIsSubmitted(true);
                    console.log('newData');
                    console.log(newData);

                    console.log('JSON.stringify(newData)');
                    console.log(JSON.stringify(newData));

                    newData.userId = localStorage.getItem('userId');
                    fetch(process.env.REACT_APP_API_URL+'/wish', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.wishesId = response.wishesId;
                            setWishesData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                            setIsSubmitted(false);
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        setIsSubmitted(true);
                        console.log('newData');
                        console.log(newData);

                        console.log('JSON.stringify(newData)');
                        console.log(JSON.stringify(newData));

                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/wish', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setWishesData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                                setIsSubmitted(false);
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('Wishes be deleted :'+oldData.wishId);
                    setIsSubmitted(true);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/wish/'+oldData.wishId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setWishesData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            }); 
                            setIsSubmitted(false); 
                    }
                }
            }}
        />
        <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
        </Backdrop>
        </div>
    );
}