import React from 'react';
import './GetStarted.css';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Auth from '../Auth/Auth';
import { Container, CssBaseline, Avatar, Typography, Grid, TextField, FormControlLabel, Checkbox, Button, Link, Box, makeStyles, Paper, FormHelperText } from '@material-ui/core';
import Copyright from '../Copyright/Copyright';   
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TermsOfUse from './TermsOfUse';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}  

const validationSchema = Yup.object({
    firstName: Yup.string().required('Required').matches(/^[A-Za-z \-']*$/, "Numbers and special characters not allowed, Max length 25").max(25, 'Max characters allowed is 25'),
    lastName: Yup.string().required('Required').matches(/^[A-Za-z \-']*$/, "Numbers and special characters not allowed.").max(25, 'Max characters allowed is 25'),
    email: Yup.string().required('Required').email('Invalid email').max(50, 'Max characters allowed is 50'),
    password: Yup.string().required('Required').matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, "Must Contain 8-16 characters, One Uppercase, One Lowercase, One Number and one special case character"),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password')], 'Should be same as Password'),
    termsOfUse : Yup.boolean().required('Required').oneOf([true], "The terms of use must be accepted.")
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function GetStarted(props) {

    const classes = useStyles();
    
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            termsOfUse : false
        },
        validationSchema,
        onSubmit: values => {
            console.log('GetStarted:OnSubmit');
            console.log(values);
            setIsSubmitted(true);
            
            Auth.handleRegistration(props, values)
                .then(resp => {
                    console.log('GetStarted:PostRegistration:resp');
                    console.log(resp);
                    if (resp && resp === 'SUCCESS') {
                        console.log('Registration Success!');
                        setIsSubmitted(false);
                        setSuccessDialogue(true);
            
                        //TODO:Display Error on Screen

                    }
                    else {
                        console.log('Registration Failed!');
                        setIsSubmitted(false);
                        setErrorMessage(resp);
                    }
                });
        }
    });

    function handleTermsOfUse(isAggreed) {
        console.log('GetStarted:handleTermsOfUse():isAggreed?');
        console.log(isAggreed);
        formik.setFieldValue('termsOfUse', isAggreed, true);
    }

    const [successDialogue, setSuccessDialogue] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState();

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setSuccessDialogue(false);
        setIsSubmitted(false);
        setErrorMessage();
    };

    const handleOkay = () => {
        console.log('GetStarted:SuccessDialogue->handleOkay()');
        setSuccessDialogue(false);
        props.history.push('/login');
      };

    return (<Container component="main" maxWidth="xs" style={{marginTop : '6%', maxWidth : '600px'}}>
                <CssBaseline />
                <Paper elevation={20} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <PersonAddIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    id="firstName"
                                    label="First Name"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.firstName && formik.touched.firstName}
                                    helperText={formik.errors.firstName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.lastName && formik.touched.lastName}
                                    helperText={formik.errors.lastName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.email && formik.touched.email}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.password && formik.touched.password}
                                    helperText={formik.errors.password}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                                    helperText={formik.errors.confirmPassword}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="flex-row-center">
                                <FormControlLabel
                                    control={<Checkbox required color="primary"
                                                    name="termsOfUse"
                                                    id="termsOfUse"
                                                    checked={formik.values.termsOfUse}
                                                    value={formik.values.termsOfUse}
                                                    onChange={formik.handleChange}
                                                    error={formik.errors.TermsOfUse && formik.touched.TermsOfUse} />}
                                                    label="I have read and agree to the "
                                    style={{marginRight:'5px'}}
                                />
                                <TermsOfUse handleTermsOfUse={handleTermsOfUse}/>
                                </div>
                                <FormHelperText>{formik.errors.TermsOfUse}</FormHelperText>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            disabled={isSubmitted}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign Up
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                <Box mt={5}>
                    <Copyright />
                </Box>

                <Dialog
                    fullScreen={fullScreen}
                    open={successDialogue}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{display:'flex', flexDirection : 'row'}}>
                            <div><CheckCircleOutlineIcon htmlColor="green" fontSize="10px" style={{fontSize: '3.0rem'}}/></div>
                            <div>Thank you for signing up with us!</div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link. <br/> <br/>
                        If you did not find it in Inbox, please check your Spam or Promotions folders. Also, please verify that you entered a valid email address in our sign-up form. <br/><br/>
                        If you need assistance, please contact us.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{margin:'10px'}}>
                        <Button onClick={handleOkay} color="primary" variant="contained">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                    <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
                    </Backdrop>
                    <Snackbar open={!successDialogue && errorMessage} 
                                autoHideDuration={60000} onClose={handleClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="error">
                          {errorMessage}
                        </Alert>
                    </Snackbar>
            </Container>);
}

export default GetStarted;