import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

export default function TermsOfUse(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDisagree = () => {
    console.log('TermsOfUse:handleDisagree()');
    setOpen(false);
    props.handleTermsOfUse(false);
  };

  const handleAgree = () => {
    console.log('TermsOfUse:handleDisagree()');
    setOpen(false);
    props.handleTermsOfUse(true);
  };

  const handleClose = () => {
    console.log('TermsOfUse:handleClose()');
    setOpen(false);
    props.handleTermsOfUse(false);
  };

  return (
    <div>
      <Link style={{textDecoration : 'underline', cursor : 'pointer'}} color="primary" onClick={handleClickOpen}>
        Terms of Use
      </Link>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <div style={{display:'flex', flexDirection : 'row'}}>
            <div>Manage My Globe - Terms of Use</div>
          </div>
        </DialogTitle>
          
        <DialogContent>
          <DialogContentText>
            Last Updated: July 04 2020 <br/>
            This is an aggreement between you and MMG. <br/>
            .................................................................<br/>
            .................................................................<br/>
            .................................................................<br/>
            [...Termination Clause...]<br/>
            .................................................................<br/>
            By accessing or using the Service, you agree to be bound by these rules
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{margin:'10px'}}>
          <Button onClick={handleDisagree} color="secondary" variant="contained">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary" variant="contained">
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
