import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Subscriptions() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value: localStorage.getItem('userId')
    })

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [state, setState] = React.useState({
        columns: [
            { title: 'Provider', field: 'provider' },
            { title: 'Description', field: 'description' },
            { title: 'Frequency', field: 'frequency', lookup: { 'MONTHLY': 'Monthly', 'QUARTERLY': 'Quarterly', 'ANNUALLY': 'Annually' }, },
            { title: 'Amount', field: 'amount', 
                validate: rowData => rowData && rowData.amount && (isNaN(rowData.amount) || (rowData.amount < 0 || rowData.amount > 10000)) ? 
                {isValid : false, helperText: 'Amount should be a number between 0 and 10,000'} : {isValid:true, helperText:''},  },
            { title: 'Payment Method', field: 'paymentMethod', lookup: { 'CREDIT_CARD': 'Credit Card', 'BANK_ACCOUNT': 'Bank A/C' }, },
            { title: 'Start Date', field: 'startDate', type: 'date', format: "MM/dd/yyyy", variant: "inline", margin: "normal" },
            { title: 'End Date', field: 'endDate', type: 'date', format: "MM/dd/yyyy", variant: "inline", margin: "normal", 
                validate: rowData => rowData && rowData.endDate && rowData.endDate < (new Date(rowData.startDate)) ? 
                {isValid : false, helperText: 'End date must be greater than Start Date'} : {isValid:true, helperText:''},  },
            { title: 'Status', field: 'status', lookup: { 'ACTIVE': 'Active', 'INACTIVE': 'Inactive', 'PAUSED': 'Paused' }, }
        ]
    });

    const [subscriptionData, setSubscriptionData] = React.useState({
        data: [],
    });

    useEffect(() => {
        console.log('Managesubscription : useEffect() : start');
        setIsSubmitted(true);
        fetch(process.env.REACT_APP_API_URL + '/subscription/' + localStorage.getItem('userId'), {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                setSubscriptionData(
                    {
                        data: response
                    }
                );
                setIsSubmitted(false);
            })
            .catch(error => console.log(error));
    }, [userId]);

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsSubmitted(false);
    };

    return (
        <div style={{ marginTop: '5%', marginLeft: '2%', marginRight: '2%', marginBottom: '2%', minHeight: '60vh' }}>
            <MaterialTable
                title="Manage Subscriptions"
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Add Subscriptions',
                    }
                }}
                options={{
                    search: false,
                    paging: false,
                    toolbarButtonAlignment: "left",
                    actionsColumnIndex: -1,
                    headerStyle: {
                        textTransform: capitalize,
                        backgroundColor: '#0048BA',
                        color: '#FFF'
                    }
                }}
                columns={state.columns}
                data={subscriptionData.data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            resolve();
                            setIsSubmitted(true);
                            
                            console.log('newData');
                            console.log(newData);

                            console.log('JSON.stringify(newData)');
                            console.log(JSON.stringify(newData));
                            
                            newData.userId = localStorage.getItem('userId');
                            fetch(process.env.REACT_APP_API_URL + '/subscription', {
                                method: 'POST',
                                body: JSON.stringify(newData),
                                headers: new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type': 'application/json'
                                })
                            }).then(resp => resp.json())
                                .then(response => {
                                    newData.subscriptionId = response.subscriptionId;
                                    setSubscriptionData((prevState) => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                    setIsSubmitted(false);
                                });
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            resolve();
                            setIsSubmitted(true);
                            console.log('newData');
                            console.log(newData);

                            console.log('JSON.stringify(newData)');
                            console.log(JSON.stringify(newData));

                            newData.userId = localStorage.getItem('userId');
                            fetch(process.env.REACT_APP_API_URL + '/subscription', {
                                method: 'POST',
                                body: JSON.stringify(newData),
                                headers: new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type': 'application/json'
                                })
                            }).then(resp => resp.json())
                                .then(response => {
                                    setSubscriptionData((prevState) => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                    setIsSubmitted(false);
                                });
                        }),
                    onRowDelete: async (oldData) => {
                        console.log('subscription be deleted :' + oldData.subscriptionId);
                        setIsSubmitted(true);
                        let response = await fetch(process.env.REACT_APP_API_URL + '/subscription/' + oldData.subscriptionId, {
                            method: 'DELETE',
                            headers: new Headers({
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            })
                        });
                        console.log('DELETE home response');
                        console.log(response);
                        if (response.ok) {
                            setSubscriptionData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                            setIsSubmitted(false);
                        }
                    }
                }}
            />
            <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
            </Backdrop>
        </div>
    )
};

export default Subscriptions;