import React, { useEffect } from 'react';
import './HomeHeader.css';
import {Link} from 'react-router-dom';
import Auth from '../Auth/Auth';
import { AppBar, Toolbar, Button, makeStyles, MenuItem, Popover } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      textTransform : "none"
    }
  }));

function HomeHeader(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const loginMenuId = open ? 'loginMenu' : undefined;

    const [healthMenuAnchorEl, setHealthMenuAnchorEl] = React.useState(null);
    const healthMenuOpen = Boolean(healthMenuAnchorEl);
    const healthMenuId = healthMenuOpen ? 'healthMenu' : undefined;

    const [servicesMenuAnchorEl, setServicesMenuAnchorEl] = React.useState(null);
    const servicesMenuOpen = Boolean(servicesMenuAnchorEl);
    const servicesMenuId = servicesMenuOpen ? 'servicesMenu' : undefined;

    const [checklistMenuAnchorEl, setChecklistMenuAnchorEl] = React.useState(null);
    const checklistMenuOpen = Boolean(checklistMenuAnchorEl);
    const checklistMenuId = checklistMenuOpen ? 'checklistMenu' : undefined;

    const [menuSelected, setMenuSelected] = React.useState({
        value : 'Home'
    });

    const handleClick = (event) => {
        console.log('event.currentTarget');
        console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        console.log('HomeHeader:handleClose()');
        setAnchorEl(null);
    }

    const healthMenuHandleClose = () => {
        console.log('HomeHeader:healthMenuHandleClose()');
        setHealthMenuAnchorEl(null);
    }

    const servicesMenuHandleClose = () => {
        console.log('HomeHeader:servicesMenuHandleClose()');
        setServicesMenuAnchorEl(null);
    }

    const checklistMenuHandleClose = () => {
        console.log('HomeHeader:checklistMenuHandleClose()');
        setChecklistMenuAnchorEl(null);
    }

    const handleHelp = () => {
        console.log('HomeHeader:handleHelp()');
        setAnchorEl(null);
    }

    function handleSettings() {
        console.log('HomeHeader:handleSettings()');
        setAnchorEl(null);
        //props.navigateToSettings();
        setMenuSelected({
            value : ''
        });
    }

    function onLogout() {
        console.log('HomeHeader:onLogout()');
        setAnchorEl(null);
        Auth.handleLogout();
        props.handleLogout(props);
        setMenuSelected({
            value : ''
        });
    }

    useEffect(() => {
        console.log('HomeHeader : useEffect : Start: Running on <<'+process.env.NODE_ENV+ '>> environment');
        console.log('API Base URL = '+process.env.REACT_APP_API_URL);
        console.log('HomeHeader:render():Props Received :');
        console.log(props.isLoggedIn);
        console.log('HomeHeader : useEffect : End'); 
    }, ['dummy']);

    function onCoreMenuChange(selectedValue) {
        console.log('onCoreMenuChange : start, current menuSelected ==> '+menuSelected.value);
        setMenuSelected({
            value : selectedValue
        });
        console.log('New menuSelected value ==> '+menuSelected.value);
        healthMenuHandleClose();
        servicesMenuHandleClose();
        checklistMenuHandleClose();
    }

    function onCoreMenuChangeHealth(event, selectedValue) {
        console.log('onCoreMenuChangeHealth : start, current menuSelected ==> '+menuSelected.value);
        setMenuSelected({
            value : selectedValue
        });
        console.log('New menuSelected value ==> '+menuSelected.value);
        setHealthMenuAnchorEl(event.currentTarget);
    }

    function onCoreMenuChangeServices(event, selectedValue) {
        console.log('onCoreMenuChangeServices : start, current menuSelected ==> '+menuSelected.value);
        setMenuSelected({
            value : selectedValue
        });
        console.log('New menuSelected value ==> '+menuSelected.value);
        setServicesMenuAnchorEl(event.currentTarget);
    }

    function onCoreMenuChangeChecklist(event, selectedValue) {
        console.log('onCoreMenuChangeChecklist : start, current menuSelected ==> '+menuSelected.value);
        setMenuSelected({
            value : selectedValue
        });
        console.log('New menuSelected value ==> '+menuSelected.value);
        setChecklistMenuAnchorEl(event.currentTarget);
    }

    return (<div className="flex-row-center">
            <AppBar style={{marginBottom : '10px'}}>
                <Toolbar>
                <div className="homeHeaderLogo">
                            {/* <Link to="/"> */}
                                <img src={window.location.origin + '/globe.png'} alt="MMG Logo" 
                                     style={{maxWidth : '220px'}}/>
                            {/* </Link> */}
                    </div>
                    <div className="homeHeaderCenter topBarMenuItem">
                        {!props.isLoggedIn ?
                            <div></div>
                            :
                            <div className={classes.root}>
                                <Link to="/home">
                                    <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Home' ? '#61dafb' : '#fff'), 
                                                    textDecoration : (menuSelected.value === 'Home' ? 'underline' : 'inherit')}} 
                                            onClick={() => {onCoreMenuChange('Home')}}>
                                        Home
                                    </Button>
                                </Link>
                                {/* <Link to="/expenses">
                                    <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Expenses' ? '#61dafb' : '#fff'), 
                                                    textDecoration : (menuSelected.value === 'Expenses' ? 'underline' : 'inherit')}} 
                                            onClick={() => {onCoreMenuChange('Expenses')}}>
                                        Expenses
                                    </Button>
                                </Link> */}
                                <Link to="/documents">
                                    <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Documents' ? '#61dafb' : '#fff'),
                                                    textDecoration : (menuSelected.value === 'Documents' ? 'underline' : 'inherit')}} 
                                            onClick={() => {onCoreMenuChange('Documents')}}>
                                                Documents
                                    </Button>
                                </Link>
                                <Button color="inherit" className={classes.button} 
                                        style={{color : (menuSelected.value === 'Services' ? '#61dafb' : '#fff'), 
                                                textDecoration : (menuSelected.value === 'Services' ? 'underline' : 'inherit')}} 
                                        onClick={(event) => {onCoreMenuChangeServices(event, 'Services')}}>
                                            Services
                                </Button>
                                <Link to="/checklist">
                                <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Checklist' ? '#61dafb' : '#fff'),
                                                    textDecoration : (menuSelected.value === 'Checklist' ? 'underline' : 'inherit')}} 
                                            onClick={(event) => {onCoreMenuChange('Checklist')}}>
                                                Checklist
                                </Button>
                                </Link>
                                <Link to="/wishes">
                                    <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Wishes' ? '#61dafb' : '#fff'), 
                                                    textDecoration : (menuSelected.value === 'Wishes' ? 'underline' : 'inherit')}} 
                                            onClick={() => {onCoreMenuChange('Wishes')}}>
                                                Wishes
                                    </Button>
                                </Link>
                                <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Health' ? '#61dafb' : '#fff'), 
                                                    textDecoration : (menuSelected.value === 'Health' ? 'underline' : 'inherit')}} 
                                            onClick={(event) => {onCoreMenuChangeHealth(event, 'Health')}}>
                                                Health
                                </Button>
                                <Link to="/contacts">
                                    <Button color="inherit" className={classes.button} 
                                            style={{color : (menuSelected.value === 'Contacts' ? '#61dafb' : '#fff'), 
                                                    textDecoration : (menuSelected.value === 'Contacts' ? 'underline' : 'inherit')}} 
                                            onClick={() => {onCoreMenuChange('Contacts')}}>
                                                Contacts
                                    </Button>
                                </Link>
                                {/* <Link to="/tools"><Button color="inherit" disabled className={classes.button} style={{color: 'darkgray'}}>Tools</Button></Link>
                                <Link to="/invite"><Button color="inherit" disabled className={classes.button} style={{color: 'darkgray'}}>Invite A Friend</Button></Link>*/}


                                <Popover
                                    id={checklistMenuId}
                                    open={checklistMenuOpen}
                                    anchorEl={checklistMenuAnchorEl}
                                    onClose={checklistMenuHandleClose}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                    }}
                                >
                                    <Link to="/checklist">
                                        <MenuItem onClick={() => {onCoreMenuChange('Checklist')}}
                                            >Checklist
                                        </MenuItem>
                                    </Link>
                                    <Link to="/reminders">
                                    <MenuItem onClick={() => {onCoreMenuChange('Reminders')}}
                                            >Reminders
                                        </MenuItem>
                                    </Link>
                                </Popover>

                                <Popover
                                    id={healthMenuId}
                                    open={healthMenuOpen}
                                    anchorEl={healthMenuAnchorEl}
                                    onClose={healthMenuHandleClose}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                    }}
                                >
                                    <Link to="/health">
                                        <MenuItem onClick={() => {onCoreMenuChange('Health')}}
                                            >Health
                                        </MenuItem>
                                    </Link>
                                    <Link to="/health-vitals">
                                    <MenuItem onClick={() => {onCoreMenuChange('Health')}}
                                            >Health Vitals
                                        </MenuItem>
                                    </Link>
                                </Popover>

                                <Popover
                                    id={servicesMenuId}
                                    open={servicesMenuOpen}
                                    anchorEl={servicesMenuAnchorEl}
                                    onClose={servicesMenuHandleClose}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                    }}
                                >
                                    <Link to="/subscriptions">
                                        <MenuItem onClick={() => {onCoreMenuChange('Subscriptions')}}
                                            >Subscriptions
                                        </MenuItem>
                                    </Link>
                                    <Link to="/tasks">
                                    <MenuItem onClick={() => {onCoreMenuChange('Tasks')}}
                                            >Tasks
                                        </MenuItem>
                                    </Link>
                                </Popover>



                            </div>
                        }
                    </div>
                    {!props.isLoggedIn ? 
                        <div className="homeButtonsPreLogin">
                        <Link to="/login">
                            <Button variant="contained"
                                    color="default"
                                    className={classes.button}
                                    startIcon={<LockOpenIcon/>}>
                                Login
                            </Button>
                        </Link>
                        <Link to="/start">
                            <Button startIcon={<PersonAddIcon/>}
                                    color="default"
                                    variant="contained"
                                    className={classes.button}>
                                Get Started
                            </Button>
                        </Link>
                    </div> 
                    : 
                    <div className="homeButtonsPostLogin">
                        <img style={{maxWidth:'50px', borderRadius : '30%', display: 'inline-block', verticalAlign: 'middle'}} src={props.profileImage}></img>
                        <Button
                            variant="contained"
                            color="default"
                            style={{display: 'inline-block'}}
                            className={classes.button}
                            endIcon={<KeyboardArrowDownSharpIcon/>}
                            aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            {props.userDetails.firstName}
                        </Button>
                        <Popover
                            id={loginMenuId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                            }}
                        >
                            
                            <Link to="/settings">
                                <MenuItem onClick={handleSettings}>Settings</MenuItem>
                            </Link>
                            <MenuItem onClick={handleHelp}>Help Center</MenuItem>
                            <MenuItem onClick={onLogout}>Log out</MenuItem>
                        
                        </Popover>
                    </div>
                    }
                </Toolbar>
            </AppBar>
            </div>);
}

export default HomeHeader;