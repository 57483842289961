import React, {useEffect} from "react";
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Contacts() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [state, setState] = React.useState({
        columns: [
            { title: 'Category', field: 'category' },
            { title: 'Service Area', field: 'serviceArea' },
            { title: 'Description', field: 'description' },
            { title: 'Company Name', field: 'companyName' },
            { title: 'Name', field: 'name' },
            { title: 'Email', field: 'email' },
            { title: 'Phone', field: 'phone' },
            { title: 'Rating', field: 'rating' }
        ]
    });

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const[contactData, setContactData] = React.useState({
        data: []
    });

    useEffect(() => {

        console.log('Managecontactes : useEffect() : start');

        setIsSubmitted(true);

        fetch(process.env.REACT_APP_API_URL+'/contact/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            console.log(response);
            setContactData(
                {
                    data:response
                }
            );
            setIsSubmitted(false);
            })
            .catch(error => console.log(error));
    }, [userId]);

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsSubmitted(false);
    };

    return(
        <div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
            <MaterialTable
            title="Manage Contacts"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Contacts',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF'
                }
            }}
            columns={state.columns}
            data={contactData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    setIsSubmitted(true);
                    console.log('newData');
                    console.log(newData);

                    console.log('JSON.stringify(newData)');
                    console.log(JSON.stringify(newData));

                    newData.userId = localStorage.getItem('userId');
                    fetch(process.env.REACT_APP_API_URL+'/contact', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.contactId = response.contactId;
                            setContactData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                            setIsSubmitted(false);
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        setIsSubmitted(true);
                        console.log('newData');
                        console.log(newData);

                        console.log('JSON.stringify(newData)');
                        console.log(JSON.stringify(newData));

                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/contact', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setContactData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                                setIsSubmitted(false);
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('Contact be deleted :'+oldData.contactId);
                    setIsSubmitted(true);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/contact/'+oldData.contactId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setContactData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                            setIsSubmitted(false);
                    }
                }
            }}
        />
        <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
            </Backdrop>
        </div>
    );
}