import React, { useEffect } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

export default function UserVerification(props) {

    const[verificationStatus, setVerificationStatus] = React.useState({
        value:"API_CALL_NOT_MADE"
    });

    useEffect(() => {
        console.log('UserVerification::Props');
        console.log(props);
        let userId = props.location.pathname.split("/")[2];
        //let  = path.split("/")[2];
        console.log('UserId ==>'+userId);

        let token = props.location.search.split("=")[1];
        console.log('token ==>'+token);

        console.log('REACT_APP_API_URL ==>'+process.env.REACT_APP_API_URL);

        fetch(process.env.REACT_APP_API_URL+'/user-verification/'+userId+'?token='+token)
            .then(resp => resp.json())
            .then(data => {
                console.log('VerificationStatus from response===>'+data.verificationStatus);
                setVerificationStatus({
                    value :data.verificationStatus 
                })
            });
    }, 'dummy-value');

    

    return(<div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
                <div>
                    {verificationStatus.value == 'API_CALL_NOT_MADE' ?
                    <div>
                        <div><CircularProgress htmlColor="royalblue" fontSize="10px" style={{fontSize: '5.0rem'}}/></div> 
                        <div>Please wait while we are verifying your email</div>
                    </div>
                    :
                    (verificationStatus.value === 'NOT_REGISTERED' ?
                    <div>
                        <div><InfoIcon htmlColor="royalblue" fontSize="10px" style={{fontSize: '5.0rem'}}/></div> 
                        <div>Looks like you have not yet Signed up with us, Please use 'Get Started' to Sign up.</div>
                    </div>
                    :
                            (verificationStatus.value === 'ALREADY_VERIFIED' ?
                            <div>
                                <div><CheckCircleOutlineIcon htmlColor="green" fontSize="10px" style={{fontSize: '5.0rem'}}/></div>
                                <div>You are already verified your email, Please use 'Login'.</div>
                            </div>:
                                    (verificationStatus.value === 'VERIFIED' ?
                                    <div>
                                        <div><CheckCircleOutlineIcon htmlColor="green" fontSize="10px" style={{fontSize: '5.0rem'}}/></div>
                                        <div><h2>Congratulations!</h2></div> 
                                        <div>You have successfully verified an email address. You can now start using Manage My Globe services with this email address. Please use 'Login'.</div>
                                        <div>Thank you for using Manage My Globe services!</div>
                                    </div>:
                                            (verificationStatus.value === 'EXPIRED' ?
                                            <div>
                                                <div><WarningIcon htmlColor="orange" fontSize="10px" style={{fontSize: '5.0rem'}}/></div>
                                                <div>We are sorry, Email link is expired! Please use 'Login' to reverify email.</div>
                                            </div>:
                                            <div>Unexpected, please contact Manage My Globe team</div>
                                            )
                                    )
                            )
                    )
                    
                }
                </div>
            </div>);
}