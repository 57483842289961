// export const settingsMainMenu = [
//     'General', 'Finance'
// ];

export const settingsMenu = [
    {
        settingsMainMenu: '',
        settingsSubMenu : [
            {
                title : "Vacation",
                component : "ChecklistVacation",
                to : "/checklist-vacation",
                disabled : false,
                display : true
            },
            {
                title : "Evacuation",
                component : "ChecklistEvacuation",
                to : "/checklist-evacuation",
                disabled : false,
                display : true
            },
            {
                title : "Shopping and Essentials",
                component : "ChecklistShoppingAndEssential",
                to: "/checklist-shoppingandessential",
                disabled : false,
                display : true
            }
        ]
    }
]