import React from 'react';
import { Container, CssBaseline, Paper, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { settingsMenu } from './ChecklistMenuList.js';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Route, MemoryRouter } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import ChecklistVacation from './checklist-components/ChecklistVacation.js';
import ChecklistEvacuation from './checklist-components/ChecklistEvacuation.js';
import ChecklistShoppingAndEssential from './checklist-components/ChecklistShoppingAndEssential.js';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 20px 20px 0px',
        minHeight : '80%'
    },
}));


function ListItemLink(props) {
    const { to, open, primary, ...other } = props;
    
    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
      );

    function handleClick() {
        console.log('ListItemLink : settingsMenuSelected() : Start&End');
    }

    return (
        <ListItem button component={renderLink} to={to} {...other}>
          <ListItemText primary={primary} onClick={handleClick} />
          {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </ListItem>
    );
}


export default function CheckList() {

    const classes = useStyles();

    const [open, setOpen] = React.useState(true);

    const [settingsMenuSelected, setSettingsMenuSelected] = React.useState({
        value : 'ChecklistVacation'
    })

    // const handleClick = () => {
    //     setOpen((prevOpen) => !prevOpen);
    // };

    function onSettingsMenuChange(newValue) {
        console.log('Settings : onSettingsMenuChange() : Start : newValue ==>'+newValue);
        setSettingsMenuSelected({
            value : newValue
        })
    }

    return (
        <Container component="main" style={{marginTop : '6%', maxWidth:'100%'}}>
            <CssBaseline />
            <Paper elevation={20} className={classes.paper}>
                <MemoryRouter initialIndex={0}>
                    <Grid container>
                        <Grid item sm={2}>
                            <Paper elevation={10} style={{padding:'30px', minHeight: '100%', marginTop: '-20px'}}>
                                {settingsMenu.map(sm => {
                                    return <div><Typography style={{textAlign:'left'}} gutterBottom>{sm.settingsMainMenu}</Typography>
                                                {  sm.settingsSubMenu.map(sbm => {
                                                    return <ListItemLink disabled={sbm.disabled} to={sbm.component} 
                                                                primary={sbm.title} key={sbm.component}
                                                                onClick={() => {onSettingsMenuChange(sbm.component)}}
                                                                style={{backgroundColor : settingsMenuSelected.value === sbm.component ? '#203bd3' : 'inherit',
                                                                        color : settingsMenuSelected.value === sbm.component ? '#fff' : 'inherit'}}/>
                                                            
                                                    })
                                                }
                                            </div>
                                    })
                                }
                            </Paper>
                        </Grid>
                        <Grid item sm={10} style={{padding : '0px 0px 0px 10px'}}>
                            <Route>
                                {({ location }) => (
                                    location.pathname === "/" ?
                                    <ChecklistVacation/>
                                    :
                                    (
                                        (location.pathname === "/CheckListVacation" ? <ChecklistVacation/> :
                                        (location.pathname === "/ChecklistEvacuation") ? <ChecklistEvacuation/> :
                                            ((location.pathname === "/ChecklistShoppingAndEssential") ? <ChecklistShoppingAndEssential/> : <ChecklistVacation/>))
                                ))}
                            </Route>
                        </Grid>
                    </Grid>
                </MemoryRouter> 
            </Paper>
        </Container>)
};


                                