import React, {useEffect} from 'react';
import MaterialTable from 'material-table';



export default function ManageVehicle() {

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [state, setState] = React.useState({
        columns: [
            { title: 'Type', field: 'type', lookup: { 'Auto': 'Auto', 'Commercial': 'Commercial', 'Motorcycle': 'Motorcycle', 'Off Highway': 'Off Highway', 'Trailer Coach': 'Trailer Coach', 'Others': 'Others' }, },
            { title: 'Owner', field: 'memberId', lookup : {} },
            { title: 'VIN', field: 'vin' },
            { title: 'License', field: 'license' },
            { title: 'Make', field: 'make' },
            { title: 'Model', field: 'model' },
            { title: 'Year', field: 'year', type: 'numeric' },
            { title: 'State', field: 'state' },
            { title: 'Zip', field: 'zip' },
        ]
    });

    const[vehicleData, setVehicleData] = React.useState({
        data: []
    });

    useEffect(() => {

        console.log(state.columns[1].lookup);

        console.log('ManageVehicle : useEffect() : start');

        fetch(process.env.REACT_APP_API_URL+'/family/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            var owners = {};
            response.forEach(element => {
                owners[element.memberId] = element.firstName;
                console.log(owners);
            });
            
            setState({
                columns : [
                    { title: 'Type', field: 'type', lookup: { 'Auto': 'Auto', 'Commercial': 'Commercial', 'Motorcycle': 'Motorcycle', 'Off Highway': 'Off Highway', 'Trailer Coach': 'Trailer Coach', 'Others': 'Others' }, },
                    { title: 'Owner', field: 'memberId', lookup : owners },
                    { title: 'VIN', field: 'vin' },
                    { title: 'License', field: 'license' },
                    { title: 'Make', field: 'make' },
                    { title: 'Model', field: 'model' },
                    { title: 'Year', field: 'year', type: 'numeric' },
                    { title: 'State', field: 'state' },
                    { title: 'Zip', field: 'zip' },
                ]
            });

                fetch(process.env.REACT_APP_API_URL+'/vehicle/'+localStorage.getItem('userId'), {
                    method: 'GET',
                    headers : new Headers({
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    })
                })
                .then(res => res.json())
                .then(response => {
                    console.log(response);
                    setVehicleData(
                        {
                            data:response
                        }
                    );
                  })
                  .catch(error => console.log(error));



            })
          .catch(error => console.log(error));

      }, [userId]);

    return (
        <MaterialTable
            title="Manage Vehicles"
            localization={{
                body: {
                    emptyDataSourceMessage: <h5> Add Vehicles </h5>,
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    backgroundColor: '#0048BA',
                    color: '#FFF',
                    padding : '5px'
                }
            }}
            columns={state.columns}
            data={vehicleData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    console.log('newData');
                    console.log(newData);
                    newData.userId = localStorage.getItem('userId');
                    fetch(process.env.REACT_APP_API_URL+'/vehicle', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.memberId = response.memberId;
                            setVehicleData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        console.log('newData');
                        console.log(newData);
                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/vehicle', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setVehicleData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('vehicle be deleted :'+oldData.vehicleId);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/vehicle/'+oldData.vehicleId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setVehicleData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                    }
                }
            }}
        />
    );
}
