// export const settingsMainMenu = [
//     'General', 'Finance'
// ];

export const settingsMenu = [
    {
        settingsMainMenu: 'Profile',
        settingsSubMenu : [
            {
                title : "Manage Family",
                component : "ManageFamily",
                to : "/managefamily"
            },
            {
                title : "Manage Home",
                component : "ManageHome",
                to : "/managehome"
            },
            
            {
                title : "Manage Vehicle",
                component : "ManageVehicle",
                to: "/managevehicle"
            }
            // {
            //     title : "Manage Payment",
            //     component : "ManagePayment",
            //     to: "/managepayment",
            //     disabled : false,
            //     display : true
            // }
        ]
    },
    {
        settingsMainMenu: 'Security',
        settingsSubMenu : [
            {
                title : "Manage Password",
                component : "ManagePassword",
                to: "/managepassword"
            },
            {
                title : "Manage Email",
                component : "ManageEmail",
                to: "/manageemail"
            }
            // {
            //     title : "Login History",
            //     component : "LoginHistory",
            //     to: "/loginhistory",
            //     disabled : true
            // },
            // {
            //     title : "Two Factor Authentication",
            //     component : "TwoFactorAuthentication",
            //     to: "/twofactorauthentication",
            //     disabled : true
            // },
            // {
            //     title : "Sign Out Remotely",
            //     component : "SignOutRemotely",
            //     to: "/signoutremotely",
            //     disabled : true
            // }
        ]
    },
    {
        settingsMainMenu: 'Other',
        settingsSubMenu : [
            {
                title : "Manage Notifications",
                component : "ManageNotifications",
                to: "/managenotifications"
            },
            {
                title : "Manage Reminders",
                component : "ManageReminders",
                to: "/managereminders",
                disabled : false
            },
            // {
            //     title : "Invite Status",
            //     component : "InactiveStatus",
            //     to: "/inactivestatus",
            //     disabled : true
            // }
        ]
    }
]