import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';  

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300    
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

export default function ChecklistVacation() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [checklistNames, setChecklistNames] = React.useState({
        data: ['Add new']
    });

    const [state, setState] = React.useState({
        columns: []
    });

    const[checklistData, setChecklistData] = React.useState({
        data: []
    });
  
    const [selectedName, setSelectedName] = React.useState('');

    const [openEnterNameDialogue, setOpenEnterNameDialogue] = React.useState(false);
  
    const handleCloseOpenEnterNameDialogue = () => {
        setOpenEnterNameDialogue(false);
        setSelectedName('');
    };

    const handleAddOpenEnterNameDialogue = () => {
        setChecklistNames(() => {
            checklistNames.data.push(selectedName);
            return checklistNames;
        });
        setOpenEnterNameDialogue(false);
    }


    const handleVacationChange = (event) => {
        console.log('handleVacationChange:start');
        console.log('event.target.value : '+event.target.value);
        setSelectedName(() => {
            return event.target.value
        });
        if(event.target.value === 'Add new') {
            setOpenEnterNameDialogue(true);
            setSelectedName('');
        }           
        else {
            fetch(process.env.REACT_APP_API_URL+'/checklist-vacation/'+localStorage.getItem('userId')+'/'+event.target.value
                    , {
                    method: 'GET',
                    headers : new Headers({
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    })
                })
                .then(res => res.json())
                .then(response => {
                    console.log(response);
                    setChecklistData(
                        {
                            data:response
                        }
                    );
                  })
                  .catch(error => console.log(error));
        }
    }

    const handleIsCheckChange = (rowData) => {
        console.log('handleIsCheckChange:start');
        console.log('rowData, current value==>'+JSON.stringify(rowData));
        console.log('isChecked, current value==>'+rowData.isChecked);

        if(rowData.isChecked === 'Y'){
            console.log('Changing to N');
            rowData.isChecked = 'N';
        }
        else if(rowData.isChecked === 'N') {
            console.log('Changing to Y');
            rowData.isChecked = 'Y';
        }
        console.log('isChecked, after changing, before committing==>'+rowData.isChecked);

        rowData.userId = localStorage.getItem('userId');
        fetch(process.env.REACT_APP_API_URL+'/checklist-vacation', {
            method: 'POST',
            body: JSON.stringify(rowData),
            headers : new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type' : 'application/json'
            })
        }).then(resp => resp.json())
            .then(response => {
                setChecklistData((prevState) => {
                    const data = [...prevState.data];
                    data[data.indexOf(rowData)] = rowData;
                    return { ...prevState, data };
                });
            })
            .catch(error => {
                console.log("Error: "+error);
            });
    }

    const onChangeAddNewName = (event) => {
        console.log('New Name (user still entering)==>'+event.target.value);
        setSelectedName(event.target.value);
    } 

    useEffect(() => {

        console.log('MansetSelectedNamechecklistVacation : useEffect() : start');

        fetch(process.env.REACT_APP_API_URL+'/checklist-vacation/'+localStorage.getItem('userId')+'/checklist-names',
                {
                    method : 'GET',
                    headers : new Headers({
                                Authorization: 'Bearer '+localStorage.getItem('token')
                    })
                }).then(resp => resp.json())
                .then(response => {
                    console.log('Checklist Names created by User for vacation : '+JSON.stringify(response));
                    response.forEach(cn => {
                        setChecklistNames(() => {
                            checklistNames.data.push(cn);
                            return checklistNames;
                        });
                        console.log('Final ChecklistNames==> '+JSON.stringify(checklistNames));
                    });
                });

        fetch(process.env.REACT_APP_API_URL+'/family/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            var owners = {};
            response.forEach(element => {
                owners[element.memberId] = element.firstName;
                console.log(owners);
            });
            
            setState({
                columns : [
                    { title: 'Member', field: 'memberId', lookup: owners },
                    { title: 'Item', field: 'item' },
                    { title: 'Qty Required', field: 'qtyRequired', 
                        validate: rowData => rowData && rowData.qtyRequired && (isNaN(rowData.qtyRequired) || (rowData.qtyRequired < 0 || rowData.qtyRequired > 100)) ? 
                        {isValid : false, helperText: 'Qantity should be a number between 0 and 100'} : {isValid:true, helperText:''},  },
                    { title: 'Qty on Hand', field: 'qtyOnHand', 
                        validate: rowData => rowData && rowData.qtyOnHand && (isNaN(rowData.qtyOnHand) || (rowData.qtyOnHand < 0 || rowData.qtyOnHand > 100)) ? 
                        {isValid : false, helperText: 'Qantity should be a number between 0 and 100'} : {isValid:true, helperText:''},  },
                    {   title: 'Checked', field: 'isChecked', editable: 'never',
                        render: rowData => <Checkbox
                                            checked={rowData && rowData.isChecked === 'Y'}
                                            onChange={() => handleIsCheckChange(rowData)}
                                            name="checkedB" disabled={!rowData}
                                            color="primary"
                                            />
                      }
                ]
            });

            })
          .catch(error => console.log(error));

      }, [userId]);

    return (
        <div style={{marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>

        
         <FormControl variant="outlined" className={classes.formControl}>
             <InputLabel id="demo-simple-select-outlined-label">Select vacation Checklist</InputLabel>
             <Select
                 labelId="demo-simple-select-outlined-label"
                 id="demo-simple-select-outlined"
                 value={selectedName}
                 onChange={handleVacationChange}
                 label="Select vacation Checklist"
                 style={{backgroundColor: 'skyblue'}}
                 >
                 {checklistNames.data.map((e) => {
                        return (
                                    <MenuItem key={e} value={e}>{e}</MenuItem>
                                );
                    })
                 }
             </Select>
         </FormControl>
        
        {(selectedName !== 'Add new' && selectedName !== '') ?
        <MaterialTable
            title={selectedName}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Vacations',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF'
                }
            }}
            columns={state.columns}
            data={checklistData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    console.log('newData');
                    console.log(newData);

                    console.log('JSON.stringify(newData)');
                    console.log(JSON.stringify(newData));

                    newData.userId = localStorage.getItem('userId');
                    
                    console.log('newData.isChecked (Before) ==>' +newData.isChecked);
                    newData.isChecked = (newData.isChecked ==null  ? 'N' : newData.isChecked);
                    console.log('newData.isChecked (After) ==>' +newData.isChecked);

                    newData.checklistName = selectedName;
                    fetch(process.env.REACT_APP_API_URL+'/checklist-vacation', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.checklistVacationId = response.checklistVacationId;
                            setChecklistData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        console.log('newData');
                        console.log(newData);

                        console.log('JSON.stringify(newData)');
                        console.log(JSON.stringify(newData));

                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/checklist-vacation', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setChecklistData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('Document be deleted :'+oldData.DocumentId);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/checklist-vacation/'+oldData.checklistVacationId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setChecklistData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                    }
                }
            }}
        />
        :
        ''
        }

    <Dialog open={openEnterNameDialogue} onClose={handleCloseOpenEnterNameDialogue} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add new vacation</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="vacationName"
            value={selectedName}
            onChange={onChangeAddNewName}
            label="Vacation name"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOpenEnterNameDialogue} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddOpenEnterNameDialogue} color="primary"
                  disabled={selectedName === ''}>
            Add
          </Button>
        </DialogActions>
      </Dialog>


        </div>
    );
}
