import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import { capitalize, Hidden } from '@material-ui/core';
import './Expenses.css';

export default function Expenses() {
    const [state, setState] = React.useState({
        columns: [
            { title: 'Type', field: 'type' }, //, lookup: { 1: 'Self', 2: 'Spouse', 3: 'Son', 4: 'Daugther', 5: 'Father', 6: 'Mother' }, },
            { title: 'Desc', field: 'desc' },
            { title: 'Provider', field: 'provider'  },
            { title: 'Pay Date', field: 'paydate', type: 'date', format: "MM/dd/yyyy", variant: "inline", margin: "normal"},
            { title: 'Jan', field: 'jan'  },
            { title: 'Feb', field: 'feb'  },
            { title: 'Mar', field: 'mar'  },
            { title: 'Apr', field: 'apr' },
            { title: 'May', field: 'may' },
            { title: 'Jun', field: 'jun' },
            { title: 'Jul', field: 'jul' },
            { title: 'Aug', field: 'aug' },
            { title: 'Sep', field: 'sep' },
            { title: 'Oct', field: 'oct' },
            { title: 'Nov', field: 'nov' },
            { title: 'Dec', field: 'dec' },
            { title: 'Total', field: 'total', editable : false,
            render: (rowData) => <div>111</div>
                             }
        ]
    });

    const [expenseState, setExpenseState] = React.useState({
        data: [
            { type: 'Home', desc: 'Mortgage', provider: 'BofA', paydate: '12/25/2020', jan: 500, feb: 500, mar: 500, apr: 500, may: 500, jun: 500, jul: 500, aug: 500, sep: 500, oct: 500, nov: 500, dec: 500, total: '6000' }
        ],
    });

    const [totalColumns, setTotalColumns] = React.useState({
        columns: [
            { title: 'Type', field: 'type' }, //, lookup: { 1: 'Self', 2: 'Spouse', 3: 'Son', 4: 'Daugther', 5: 'Father', 6: 'Mother' }, },
            { title: 'Desc', field: 'desc' },
            { title: 'Provider', field: 'provider'  },
            { title: 'Pay Date', field: 'pay'},
            { title: 'Jan', field: 'jan'  },
            { title: 'Feb', field: 'feb'  },
            { title: 'Mar', field: 'mar'  },
            { title: 'Apr', field: 'apr' },
            { title: 'May', field: 'may' },
            { title: 'Jun', field: 'jun' },
            { title: 'Jul', field: 'jul' },
            { title: 'Aug', field: 'aug' },
            { title: 'Sep', field: 'sep' },
            { title: 'Oct', field: 'oct' },
            { title: 'Nov', field: 'nov' },
            { title: 'Dec', field: 'dec' },
            { title: 'Total', field: 'total', 
            render: (rowData) => <div>
                            {Number(rowData.jan) + Number(rowData.feb) + Number(rowData.mar) + Number(rowData.apr) + Number(rowData.may) + Number(rowData.jun) +
                            Number(rowData.jul) + Number(rowData.aug) + Number(rowData.sep) + Number(rowData.oct) + Number(rowData.nov) + Number(rowData.dec)}
                            </div>
                             }
        ]
    });

    const [totalState, setTotalState] = React.useState({
        data: [
            { type: '', desc: '', provider: '', pay: 'Total', jan: '200', feb: '200', mar: '500', apr: '500', may: '500', jun: '500', jul: '500', aug: '500', sep: '500', oct: '500', nov: '500', dec: '500', total: '6000', hiddenColumn: '', hiddenColumn2: '' },
        ],
    });

    useEffect(() => {
        console.log('Expenses : useEffect() : start');

        console.log('Expenses : useEffect() : end');
    }, 'dummy-state');


    return (
        <div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
        <MaterialTable
            title="Manage Expenses"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Expenses',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                header : true,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF',
                    fontSize : '12px',
                    padding: '2px 0px 0px 10px' 
                },
                cellStyle: {
                    fontSize : '12px',
                    padding: '2px 0px 0px 10px'
                }
            }}
            columns={state.columns}
            data={expenseState.data}
            editable={{
                onRowAdd: (newData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setExpenseState((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            if (oldData) {
                                setExpenseState((prevState) => {
                                    const data = [...prevState.data];
                                    newData.total = 6789;
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            }
                        }, 600);
                    }),
                onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            resolve();
                            setExpenseState((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                        }, 600);
                    }),
            }}
        />

<MaterialTable
            title=""
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Expenses',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                header : false,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF',
                    fontSize : '12px',
                    padding: '2px 0px 0px 10px' 
                },
                cellStyle: {
                    fontSize : '12px',
                    padding: '2px 0px 0px 10px'
                }
            }}
            actions={[
                {
                  icon: 'edit',
                  disabled: true
                },
                {
                    icon: 'delete',
                    disabled: true
                }
              ]}
            columns={totalColumns.columns}
            data={totalState.data}
            
        />
        </div>
    );
}


