import React, {Component, useEffect} from 'react';
import './ForgotPassword.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Container, CssBaseline, Avatar, Typography, Grid, TextField, FormControlLabel, Checkbox, Button, Link, Box, makeStyles, Paper, FormHelperText } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Copyright from '../Copyright/Copyright';   
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}  

const validationSchema = Yup.object({
    verificationCode: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required').matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, "Must Contain 8-16 characters, One Uppercase, One Lowercase, One Number and one special case character"),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('newPassword')], 'Should be same as Password'),
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ForgotPasswordVerification(props){

    const classes = useStyles();

    const [successDialogue, setSuccessDialogue] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const [token, setToken] = React.useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState();

    const[verificationStatus, setVerificationStatus] = React.useState({
        value:"API_CALL_NOT_MADE"
    });

    useEffect(() => {
        console.log('UserVerification::Props');
        console.log(props);
        let userId = props.location.pathname.split("/")[2];
        console.log('UserId ==>'+userId);
        setUserId(userId);

        let token = props.location.search.split("=")[1];
        console.log('token ==>'+token);
        setToken(token);

        console.log('REACT_APP_API_URL ==>'+process.env.REACT_APP_API_URL);

        
    }, 'dummy-value');

    const formik = useFormik({
        initialValues: {
            verificationCode: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema,
        onSubmit: values => {
            console.log('GetStarted:OnSubmit');
            console.log(values);
            setIsSubmitted(true);
            fetch(process.env.REACT_APP_API_URL+'/forgot-pwd-verification/'+userId+'?token='+token,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'accept': '*/*'
                },
                body: JSON.stringify(formik.values)
            }
        )
            .then(resp => resp.json())
            .then(data => {
                console.log('VerificationStatus from response===>'+data.verificationStatus);
                setVerificationStatus({
                    value :data.verificationStatus 
                });
                setSuccessDialogue(true);
                setIsSubmitted(false);
            });

        }
    });

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setSuccessDialogue(false);
        setIsSubmitted(false);
        setErrorMessage();
    };

    const handleOkay = () => {
        console.log('GetStarted:SuccessDialogue->handleOkay()');
        setSuccessDialogue(false);
        if(verificationStatus.value === 'VERIFIED' || verificationStatus.value === 'ALREADY_VERIFIED')
            props.history.push('/login');
      };

    return (<Container component="main" maxWidth="xs" style={{marginTop : '6%', maxWidth : '600px'}}>
                <CssBaseline />
                <Paper elevation={20} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <VpnKeyIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Password Reset
                    </Typography>
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    id="verificationCode"
                                    label="Verification Code"
                                    name="verificationCode"
                                    autoComplete="verificationCode"
                                    value={formik.values.verificationCode}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.verificationCode && formik.touched.verificationCode}
                                    helperText={formik.errors.verificationCode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    name="newPassword"
                                    label="New Password"
                                    type="password"
                                    id="newPassword"
                                    autoComplete="current-password"
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.newPassword && formik.touched.newPassword}
                                    helperText={formik.errors.newPassword}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                                    helperText={formik.errors.confirmPassword}
                                />
                            </Grid>
                            <Button
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitted}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                            >Submit</Button>
                        </Grid>

                        </form>
                        </Paper>
                <Box mt={5}>
                    <Copyright />
                </Box>

                <Dialog
                    fullScreen={fullScreen}
                    open={successDialogue}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{display:'flex', flexDirection : 'row'}}>
                            {verificationStatus.value === 'ALREADY_VERIFIED'
                                || verificationStatus.value === 'VERIFIED' ? 
                                <div>
                                    <div><CheckCircleOutlineIcon htmlColor="green" fontSize="10px" style={{fontSize: '3.0rem'}}/></div>
                                    <div>Thank you being member with Manage My Globe!</div>
                                </div>
                                :
                                <div>
                                    <div><ErrorIcon htmlColor="red" fontSize="10px" style={{fontSize: '3.0rem'}}/></div>
                                    <div>We could not process the request!</div>
                                </div>
                                }
                        </div>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                    <div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '15vh'}}>
                <div>
                    {verificationStatus.value == 'API_CALL_NOT_MADE' ?
                    <div>
                        <div><CircularProgress htmlColor="royalblue" fontSize="10px" style={{fontSize: '5.0rem'}}/></div> 
                        <div>Please wait while we are verifying the details.</div>
                    </div>
                    :
                    (verificationStatus.value === 'NOT_REGISTERED' ?
                    <div>
                        <div>Looks like you have not yet Signed up with us, Please use 'Get Started' to Sign up.</div>
                    </div>
                    :
                            (verificationStatus.value === 'ALREADY_VERIFIED' ?
                            <div>
                                <div>The request was already processed, Please try to 'Login' with new password. If no luck further please initiate new Forgot Password request.</div>
                            </div>:
                                    (verificationStatus.value === 'VERIFIED' ?
                                    <div>
                                        <div><h2>Congratulations!</h2></div> 
                                        <div>You have successfully reset the password. Please 'Login' with your new password.</div>
                                        <div>Thank you for using Manage My Globe services!</div>
                                    </div>:
                                            (verificationStatus.value === 'EXPIRED' 
                                            || verificationStatus.value === 'VERIFICATION_FAILED' ?
                                            <div>
                                                <div>We are sorry, Either verification code was incorrect or Email link was expired! Please check the code or initiate Forgot Password again and retry.</div>
                                            </div>:
                                            <div>Unexpected error occuered, apoligies for the inconvinence. Please contact Manage My Globe team</div>
                                            )
                                    )
                            )
                    )
                    
                }
                </div>
            </div>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{margin:'10px'}}>
                        <Button onClick={handleOkay} color="primary" variant="contained">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                    <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
                    </Backdrop>
                    <Snackbar open={!successDialogue && errorMessage} 
                                autoHideDuration={60000} onClose={handleClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="error">
                          {errorMessage}
                        </Alert>
                    </Snackbar>
            </Container>);
}

export default ForgotPasswordVerification;