import React, {Component} from 'react';
import './HomeFooter.css';

class HomeFooter extends Component {

    render() {
        return <header className="Home-footer">This is MMG Home footer</header>
    }
}

export default HomeFooter;