import React, {Component} from 'react';
import './ForgotPassword.css';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Container, CssBaseline, Avatar, Typography, Grid, TextField, FormControlLabel, Checkbox, Button, Link, Box, makeStyles, Paper, FormHelperText } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Copyright from '../Copyright/Copyright';   
import LiveHelpRounded from '@material-ui/icons/LiveHelpRounded';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}  

const validationSchema = Yup.object({
    email: Yup.string().required('Required').email('Invalid email').max(50, 'Max characters allowed is 50')
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ForgotPassword(props){

    const classes = useStyles();

    const [successDialogue, setSuccessDialogue] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState();

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema,
        onSubmit: values => {
            console.log('GetStarted:OnSubmit');
            console.log(values);
            setIsSubmitted(true);
            
            fetch(process.env.REACT_APP_API_URL+'/forgot-pwd',
                  {
                    method: 'POST',
                    body: JSON.stringify({email: formik.values.email}),
                    headers: {
                        'content-type': 'application/json',
                        'accept': '*/*'
                    }
                  })
                  .then(response => response.json())
                  .then(resp => {
                            console.log('GetStarted:PostRegistration:resp');
                            console.log(resp);
                            setIsSubmitted(false);
                            if (resp && resp.email && resp.email === formik.values.email) {
                                console.log('Email sent successfully!');
                                setSuccessDialogue(true);
                                //TODO:Display Error on Screen
                            }
                            else {
                                console.log('Could not send email!');
                                setErrorMessage('Unexpected error ocurred!');
                            }
                        });

            // Auth.handleRegistration(props, values)
            //     .then(resp => {
            //         console.log('GetStarted:PostRegistration:resp');
            //         console.log(resp);
            //         if (resp && resp === 'SUCCESS') {
            //             console.log('Registration Success!');
            //             setIsSubmitted(false);
            //             setSuccessDialogue(true);
            
            //             //TODO:Display Error on Screen

            //         }
            //         else {
            //             console.log('Registration Failed!');
            //             setIsSubmitted(false);
            //             setErrorMessage(resp);
            //         }
            //     });
        }
    });

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setSuccessDialogue(false);
        setIsSubmitted(false);
        setErrorMessage();
    };

    const handleOkay = () => {
        console.log('GetStarted:SuccessDialogue->handleOkay()');
        setSuccessDialogue(false);
        props.history.push('/login');
      };

    return (<Container component="main" maxWidth="xs" style={{marginTop : '6%', maxWidth : '600px'}}>
                <CssBaseline />
                <Paper elevation={20} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LiveHelpRounded />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password ?
                    </Typography>
                        <form className={classes.form} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    disabled={isSubmitted}
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    error={formik.errors.email && formik.touched.email}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Button
                                    type="submit"
                                    fullWidth
                                    disabled={isSubmitted}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                            >Submit</Button>
                        </Grid>

                        </form>
                        </Paper>
                <Box mt={5}>
                    <Copyright />
                </Box>

                <Dialog
                    fullScreen={fullScreen}
                    open={successDialogue}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{display:'flex', flexDirection : 'row'}}>
                            <div><CheckCircleOutlineIcon htmlColor="green" fontSize="10px" style={{fontSize: '3.0rem'}}/></div>
                            <div>Thank you for submitting your request!</div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        We have sent an email with set of instructions, please follow. <br/> <br/>
                        If you did not find it in Inbox, please check your Spam or Promotions folders. <br/><br/>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{margin:'10px'}}>
                        <Button onClick={handleOkay} color="primary" variant="contained">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                    <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
                    </Backdrop>
                    <Snackbar open={!successDialogue && errorMessage} 
                                autoHideDuration={60000} onClose={handleClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="error">
                          {errorMessage}
                        </Alert>
                    </Snackbar>
            </Container>);
}

export default ForgotPassword;