import React from 'react';
import { Typography, Container, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    
}));

function InactiveStatus() {

    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Inactive Status
                </Typography>
            </div>
        </Container>
    );
}

export default InactiveStatus;