import React, { useEffect } from "react";
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Button, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


export default function Tasks() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value: localStorage.getItem('userId')
    })


    const [state, setState] = React.useState({
        columns: [
            { title: 'Task', field: 'taskName' },
            { title: 'Description', field: 'description' },
            { title: 'Frequency', field: 'frequency', lookup: { 'MONTHLY': 'Monthly', 'QUARTERLY': 'Quarterly', 'ANNUALLY': 'Annually' }, },
            { title: 'Amount (in USD)', field: 'amount', type: 'numeric' },
            { title: 'Due Date', field: 'dueDate', type: 'date', format: "MM/dd/yyyy", variant: "inline", margin: "normal" },
            { title: 'Status', field: 'status', editable: 'never',
                render: rowData => <Checkbox
                                    checked={(rowData && rowData.status && rowData.status === 'COMPLETED')}
                                    onChange={() => showWarningDialogue(rowData)}
                                    name="checkedB" disabled={!rowData}
                                    color="primary"
                                    />
            }
        ]
    });

    const [taskData, setTaskData] = React.useState({
        data: [],
    });

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [warningDialogue, setWarningDialogue] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {

        console.log('Managetask : useEffect() : start');

        setIsSubmitted(true);

        fetchFreshData();

    }, [userId]);

    const fetchFreshData = () =>  {
        fetch(process.env.REACT_APP_API_URL + '/task/' + localStorage.getItem('userId'), {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                setTaskData(
                    {
                        data: response
                    }
                );
                setIsSubmitted(false);
            })
            .catch(error => console.log(error));
    };

    const showWarningDialogue = (rowData) => {
        setWarningDialogue(rowData);
    };

    const handleStatusChange = (rowData) => {
        setWarningDialogue(false);
        setIsSubmitted(true);
        console.log('handleIsCheckChange:start');
        console.log('rowData, current value==>'+JSON.stringify(rowData));
        console.log('status, current value==>'+rowData.status);

        if(rowData.status === 'OPEN'){
            console.log('Changing to N');
            rowData.status = 'COMPLETED';
        }
        else if(rowData.status === 'COMPLETED') {
            console.log('Changing to Y');
            rowData.status = 'OPEN';
        }
        console.log('status, after changing, before committing==>'+rowData.status);

        rowData.userId = localStorage.getItem('userId');
        fetch(process.env.REACT_APP_API_URL+'/task', {
            method: 'POST',
            body: JSON.stringify(rowData),
            headers : new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type' : 'application/json'
            })
        }).then(resp => resp.json())
            .then(response => {
                setTaskData((prevState) => {
                    const data = [...prevState.data];
                    data[data.indexOf(rowData)] = rowData;
                    return { ...prevState, data };
                });
                
                //Refresh the list, because only OPEN tasks need to be show on UI
                fetchFreshData();
                
                setIsSubmitted(false);
            })
            .catch(error => {
                console.log("Error: "+error);
            });
    };

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsSubmitted(false);
    };

    const handleWarningClose = () => {
        console.log('GetStarted:handleClose()');
        setWarningDialogue(false);
        setIsSubmitted(false);
    };

    const handleOkay = () => {
        console.log('GetStarted:SuccessDialogue->handleOkay()');
        handleStatusChange(warningDialogue);
        setWarningDialogue(false);
      };

    const handleCancel = () => {
        console.log('GetStarted:SuccessDialogue->handleOkay()');
        setWarningDialogue(false);
    };


    return (
        <div style={{ marginTop: '5%', marginLeft: '2%', marginRight: '2%', marginBottom: '2%', minHeight: '60vh' }}>
            <MaterialTable
                title="Manage Tasks"
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Add Tasks',
                    }
                }}
                options={{
                    search: false,
                    paging: false,
                    toolbarButtonAlignment: "left",
                    actionsColumnIndex: -1,
                    headerStyle: {
                        textTransform: capitalize,
                        backgroundColor: '#0048BA',
                        color: '#FFF'
                    }
                }}
                columns={state.columns}
                data={taskData.data}
                editable={{
                    onRowAdd: (newData) =>
                        new Promise((resolve) => {
                            resolve();
                            setIsSubmitted(true);
                            console.log('newData');
                            console.log(newData);

                            console.log('JSON.stringify(newData)');
                            console.log(JSON.stringify(newData));

                            newData.userId = localStorage.getItem('userId');
                            newData.status = 'OPEN';
                            fetch(process.env.REACT_APP_API_URL + '/task', {
                                method: 'POST',
                                body: JSON.stringify(newData),
                                headers: new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type': 'application/json'
                                })
                            }).then(resp => resp.json())
                                .then(response => {
                                    newData.taskId = response.taskId;
                                    setTaskData((prevState) => {


                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return { ...prevState, data };
                                    });
                                    setIsSubmitted(false);
                                });
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            resolve();
                            setIsSubmitted(true);
                            console.log('newData');
                            console.log(newData);

                            console.log('JSON.stringify(newData)');
                            console.log(JSON.stringify(newData));

                            newData.userId = localStorage.getItem('userId');
                            fetch(process.env.REACT_APP_API_URL + '/task', {
                                method: 'POST',
                                body: JSON.stringify(newData),
                                headers: new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type': 'application/json'
                                })
                            }).then(resp => resp.json())
                                .then(response => {
                                    setTaskData((prevState) => {

                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                    setIsSubmitted(false);
                                });
                        }),
                    onRowDelete: async (oldData) => {
                        console.log('task be deleted :' + oldData.taskId);
                        let response = await fetch(process.env.REACT_APP_API_URL + '/task/' + oldData.taskId, {
                            method: 'DELETE',
                            headers: new Headers({
                                Authorization: 'Bearer ' + localStorage.getItem('token')
                            })
                        });
                        console.log('DELETE home response');
                        console.log(response);
                        if (response.ok) {
                            setTaskData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });
                        }
                    }
                }}
            />
            <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
            </Backdrop>

            <Dialog
                    fullScreen={fullScreen}
                    open={warningDialogue}
                    onClose={handleWarningClose}
                    aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">
                    <div style={{display:'flex', flexDirection : 'row'}}>
                        <div><WarningRoundedIcon htmlColor="orange" fontSize="10px" style={{fontSize: '3.0rem'}}/></div>
                        <div style={{marginLeft: '20px'}}>Task Action</div>
                    </div>
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Marking this task as 'Completed' will delete this task and automatically create new one for the next frequency.
                    <br/><br/>Please click 'OK' if you are sure, otherwise click 'Cancel'.
                    <br/><br/><b>Note:</b> If you dont want new task for next frequency, please 'Cancel' here and then Delete the Task.
                </DialogContentText>
                </DialogContent>
                <DialogActions style={{margin:'10px'}}>
                    <Button onClick={handleOkay} color="primary">
                        OK
                    </Button>
                    <Button onClick={handleCancel} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
};