import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }  

const validationSchema = Yup.object({
    email: Yup.string().required('Required').email('Invalid email').max(50, 'Max characters allowed is 50'),
    newEmail: Yup.string().required('Required').email('Invalid email').max(50, 'Max characters allowed is 50'),
    password: Yup.string().required('Required')
})

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform : "none"
    },
}));

export default function ManageEmail() {
    const classes = useStyles();

    const formik = useFormik({
        initialValues : {
            email : '',
            newEmail : '',
            password: ''
        },
        validationSchema,
        onSubmit: values => {
            console.log('ManageEmail:OnSubmit');
            console.log(values);
        
            fetch(process.env.REACT_APP_API_URL+'/email-change',
                {
                    method : 'POST',
                    body : JSON.stringify({
                        email : formik.values.email,
                        newEmail : formik.values.newEmail,
                        password : formik.values.password
                    }),
                    headers : new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type' : 'application/json'
                    })
                }
            )
            .then(resp => {
                console.log('resp status');
                console.log(resp.status);
                if(resp.status === 200) {
                    setSaveState({
                        status : "SUCCESS"
                    });
                }
                else {
                    setSaveState({
                        status : "FAILED"
                    });
                }
            })
            .catch(error => {
                console.log(error);
                setSaveState({
                    status : "FAILED"
                });
            });
        }
    });

    const [saveState , setSaveState] = React.useState({
        status : null
    });

    console.log('ManageEmail:formik ?');
    console.log(formik);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSaveState({
            status : null
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Manage Email Address
                </Typography>

                {saveState.status === 'SUCCESS' ? 
                        <Snackbar open={saveState.status === 'SUCCESS'} 
                                  autoHideDuration={3000} onClose={handleClose}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                            <Alert onClose={handleClose} severity="success">
                                Email updated successfully! Please logout and re-login with new email.
                            </Alert>
                        </Snackbar> : (saveState.status === 'FAILED' ? 
                        <Snackbar open={saveState.status === 'FAILED'} 
                                  autoHideDuration={3000} onClose={handleClose}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                            <Alert onClose={handleClose} severity="error">
                                Please check your current Email/Password and try again!
                            </Alert>
                        </Snackbar> : '') }

                <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Your Current Email Address"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email && formik.touched.email}
                        helperText={formik.errors.email}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newEmail"
                        label="New Email Address"
                        name="newEmail"
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        error={formik.errors.newEmail && formik.touched.newEmail}
                        helperText={formik.errors.newEmail}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Current Password"
                        type="password"
                        id="password"
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}
                        error={formik.errors.password && formik.touched.password}
                        helperText={formik.errors.password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Submit
          </Button>
                </form>
            </div>
        </Container>
    );
}
