import React, {useEffect} from "react";
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';

export default function Reminders() {
    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [state, setState] = React.useState({
        columns: [
            { title: 'Reminder for', field: 'reminderFor' },
            { title: 'Reminder Name', field: 'reminderName' },
            { title: 'Description', field: 'description' },
            { title: 'Change Date', field: 'changeDate', type:'date', dateSetting : {locale: 'en-US'} },
            { title: 'Frequency', field: 'frequency', type: 'numeric' },
            { title: 'Reminder', field: 'reminderType', lookup: { 'EMAIL': 'Email', 'TEXT': 'Text', 'EMAIL_AND_TEXT': 'Email & Text' }, },
            { title: 'Days to Remind', field: 'daysToRemind',
                validate: rowData => rowData && rowData.daysToRemind && (isNaN(rowData.daysToRemind) || (rowData.daysToRemind < 0 || rowData.daysToRemind > 60)) ? 
                {isValid : false, helperText: 'Days should be a number between 0 and 60'} : {isValid:true, helperText:''},  },
        ]
    });

    const[reminderData, setReminderData] = React.useState({
        data: []
    });

    useEffect(() => {

        console.log('Managereminderes : useEffect() : start');

        fetch(process.env.REACT_APP_API_URL+'/reminder/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            console.log(response);
            setReminderData(
                {
                    data:response
                }
            );
            })
            .catch(error => console.log(error));
    }, [userId]);


    return(
        <div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
            <MaterialTable
            title="Manage Reminders"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Reminders',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF'
                }
            }}
            columns={state.columns}
            data={reminderData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    console.log('newData');
                    console.log(newData);

                    console.log('JSON.stringify(newData)');
                    console.log(JSON.stringify(newData));

                    newData.userId = localStorage.getItem('userId');
                    fetch(process.env.REACT_APP_API_URL+'/reminder', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.reminderId = response.reminderId;
                            setReminderData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        console.log('newData');
                        console.log(newData);

                        console.log('JSON.stringify(newData)');
                        console.log(JSON.stringify(newData));

                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/reminder', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setReminderData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('Contact be deleted :'+oldData.reminderId);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/reminder/'+oldData.reminderId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE reminder response');
                    console.log(response);
                    if (response.ok) {
                            setReminderData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                    }
                }
            }}
        />
        </div>
    );
}