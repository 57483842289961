import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import './CheckList.css';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ChecklistEvacuation() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [state, setState] = React.useState({
        columns: [
            { title: 'Type', field: 'type', lookup: {
                                                        'FAMILY': 'Family', 'DOCUMENTS': 'Documents', 'MONEY': 'Money',
                                                        'JEWELRY': 'Jewelry', 'SENTIMENTAL': 'Sentimental', 'FOOD_AND_WATER': 'Food & Water',
                                                        'TOILETRIES': 'Toiletries', 'CLOTHING': 'Clothing', 'OTHERS': 'Others'
                                                    },
            },
            { title: 'Description', field: 'description' },
            { title: 'Needed Qty', field: 'qtyRequired' },
            { title: 'Quantity', field: 'qtyOnHand' },
            { title: 'Check', field: 'isChecked', editable: 'never',
                    render: rowData => <Checkbox
                    checked={rowData && rowData.isChecked === 'Y'}
                    onChange={() => handleIsCheckChange(rowData)}
                    name="checkedB" disabled={!rowData}
                    color="primary"
                    />
            }
        ]
    });

    const[checklistData, setChecklistData] = React.useState({
        data: []
    });

    useEffect(() => {

        console.log('CheckListEvacation : useEffect() : start');
        setIsSubmitted(true);
        fetch(process.env.REACT_APP_API_URL+'/checklist-evacuation/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            console.log(response);
            setChecklistData(
                {
                    data:response
                }
            );
            setIsSubmitted(false);
            })
            .catch(error => console.log(error));
      }, [userId]);
    
      const handleIsCheckChange = (rowData) => {
        setIsSubmitted(true);
        console.log('handleIsCheckChange:start');
        console.log('rowData, current value==>'+JSON.stringify(rowData));
        console.log('isChecked, current value==>'+rowData.isChecked);

        if(rowData.isChecked === 'Y'){
            console.log('Changing to N');
            rowData.isChecked = 'N';
        }
        else if(rowData.isChecked === 'N') {
            console.log('Changing to Y');
            rowData.isChecked = 'Y';
        }
        console.log('isChecked, after changing, before committing==>'+rowData.isChecked);

        rowData.userId = localStorage.getItem('userId');
        fetch(process.env.REACT_APP_API_URL+'/checklist-evacuation', {
            method: 'POST',
            body: JSON.stringify(rowData),
            headers : new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type' : 'application/json'
            })
        }).then(resp => resp.json())
            .then(response => {
                setChecklistData((prevState) => {
                    const data = [...prevState.data];
                    data[data.indexOf(rowData)] = rowData;
                    return { ...prevState, data };
                });
                setIsSubmitted(false);
            })
            .catch(error => {
                console.log("Error: "+error);
            });
    }

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState();

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsSubmitted(false);
        setErrorMessage();
    };


    return (
        <div style={{marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
        <MaterialTable
            title="Evacuation"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Items',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF'
                }
            }}
            columns={state.columns}
            data={checklistData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    console.log('newData');
                    console.log(newData);

                    console.log('JSON.stringify(newData)');
                    console.log(JSON.stringify(newData));

                    newData.userId = localStorage.getItem('userId');

                    console.log('newData.isChecked (Before) ==>' +newData.isChecked);
                    newData.isChecked = (newData.isChecked == null ? 'N' : newData.isChecked);
                    console.log('newData.isChecked (After) ==>' +newData.isChecked);
                    
                    
                    fetch(process.env.REACT_APP_API_URL+'/checklist-evacuation', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.checklistEvacuationId = response.checklistEvacuationId;
                            setChecklistData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        console.log('newData');
                        console.log(newData);

                        console.log('JSON.stringify(newData)');
                        console.log(JSON.stringify(newData));

                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/checklist-evacuation', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setChecklistData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('checklistEvacuationId be deleted :'+oldData.checklistEvacuationId);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/checklist-evacuation/'+oldData.checklistEvacuationId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setChecklistData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                    }
                }
            }}
        />
        <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
        </Backdrop>
        </div>
    );
}
