import React from 'react';
import './Login.css';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Auth from '../Auth/Auth';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../Copyright/Copyright';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validationSchema = Yup.object({
    email : Yup.string().required('Required').email('Invalid Email'),
    //password : Yup.string().required('Required').matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,16}$/, "Must Contain 8-16 characters, One Uppercase, One Lowercase, One Number and one special case character")
})

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    lockIcon : {
        backgroundColor: 'rgb(220, 0, 78) !important'
    }
}));


function Login(props) {

    const classes = useStyles();

    const [errorMessage, setErrorMessage] = React.useState();

    const formik = useFormik({
        initialValues : {
            email : '',
            password : '',
            error : ''
        },
        onSubmit : values => {
            console.log('OnSubmit');
            console.log(values);
            Auth.handleLogin(props, values)
                .then(resp => {
                    console.log('Login:PostAuth:resp');
                    console.log(resp);
                    if(resp && resp.isVerified === 'true') {
                        console.log('Authentication Success');
                        props.handleLogin(resp);
                    }
                    else {
                        console.log('User Not Verified');
                        setErrorMessage(resp);
                    }
            });
            
        },
        validationSchema
    });

    const handleClose = () => {
        console.log('Login:handleClose()');
        setErrorMessage();
    };

    return (
        
        <Container component="main" maxWidth="xs" style={{marginTop : '6%'}}>
          <CssBaseline />
            <Paper elevation={20} className={classes.paper}>
                <Avatar className={classes.lockIcon}>
                <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                Sign in
                </Typography>
                <form className="form" onSubmit={formik.handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    error={formik.errors.email && formik.touched.email}
                    helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ''}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={formik.values.password}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    autoComplete="current-password"
                    error={formik.errors.password && formik.touched.password}
                    helperText={formik.errors.password && formik.touched.password ? formik.errors.password : ''}
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                    <Link href="/forgot_password" variant="body2">
                        Forgot password?
                    </Link>
                    </Grid>
                    <Grid item>
                    <Link href="/start" variant="body2">
                        {"Don't have an account?"}
                    </Link>
                    </Grid>
                </Grid>
                </form>
            </Paper>
          <Box mt={8}>
            <Copyright/>
          </Box>
                    <Snackbar open={errorMessage} 
                                autoHideDuration={60000} onClose={handleClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="error">
                          {errorMessage}
                        </Alert>
                    </Snackbar>
        </Container>
        
      );
}

export default Login;