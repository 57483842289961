import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from './Auth';

function ProtectedRoute({component: Component, ...rest}) {

    return (
        <Route {...rest} 
            render={props => {
                if(Auth.isAuthenticated) {
                    console.log('ProtectedRoute:Already Authenticated!');
                    return <Component {...props}/>
                }
                    
                else {
                    console.log('ProtectedRoute:Reditecting to Home!');
                    return <Redirect to={
                        {
                            pathname : "/",
                            state : {
                                from: props.location
                            }
                        }
                    }/>
                }
            }
            }/>
    );

}

export default ProtectedRoute;