import React, { useEffect } from 'react';
import './Home.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, CardContent, Fab, Grid } from '@material-ui/core';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import imageCompression from 'browser-image-compression';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Home(props) {

    const classes = useStyles();

    const [profileImage, setProfileImage] = React.useState({
        data : ''});

    const [isLoading, setIsLoading] = React.useState(false);


    useEffect(() => {
        setIsLoading(true);
        console.log('API URL ==> '+process.env.REACT_APP_API_URL);
        // fetch(process.env.REACT_APP_API_URL + '/image/1')
        // .then(resp => resp.json())
        // .then(data => {
        //     setProfileImage({data: data.image});
            setIsLoading(false);
        // })
    }, ['Dummy']);

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsLoading(false);
    };

    const handleChange = async (event) => {
        console.log('GetStarted:handleChange()');
        let img = new Image();
        if(event.target.files.length !== 0) {
            let imageFile = event.target.files[0];

            const formData = new FormData();
            formData.append(
                "file", imageFile
            )
            
            img.src = window.URL.createObjectURL(imageFile);
            img.onload = () => {  
                if(img.width < 6000 && img.height < 6000){
                    //compression/resizing logic
                    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
                    const options = {
                        maxSizeMB: 0.5,
                        maxWidthOrHeight: 1000,
                        useWebWorker: true
                      }

                      imageCompression(imageFile, options)
                        .then(function (compressedFile) {
                            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                            
                            const formData = new FormData();
                            formData.append(
                                "file", compressedFile, imageFile.name
                            )

                            setIsLoading(true);
                                fetch(process.env.REACT_APP_API_URL+'/image/upload', {
                                    method : 'POST',
                                    headers: new Headers({ 
                                                            'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                                                        }),
                                    body : formData
                                })
                                .then(resp => resp.json())
                                .then(data => {
                                    console.log('Successully uploaded your profile picture!');
                                    setProfileImage({
                                        data : 'data:image/jpeg;base64,'+data.image
                                    });
                                    setIsLoading(false);  
                                })
                        })
                    .catch(function (error) {
                        console.log(error.message);
                        setIsLoading(false);
                    });
                } else {
                    alert(`Sorry, The image size is bigger for profile pic. It's 
                           ${img.width} x ${img.height}, please resize or pic another 
                           one with less than 2000 x 2000 size image.`);
                }                
            }
        }  
    };

    return (
        <div className="homeContent">
                    This is MMG Home
        </div>
    );
}