import React from 'react';
import './HomeContent.css';

import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { isPossiblePhoneNumber } from 'react-phone-number-input'

export default function HomeContent(props) {

    const [phone, setPhone] = React.useState('12345');

    const [isPossible, setIsPossible] = React.useState(false);

    const onPhoneChange = (event) => {
        console.log('Phone Number Changed to:'+event.target.value);        
        setIsPossible(true);
        setPhone(event.target.value);
    };

    const onPhoneChange2 = (newVal) => {
        setIsPossible(true);
        console.log('Phone Number Changed to:'+newVal);        
        setIsPossible(true);
        setPhone(newVal);
    };

    return (<div className="homeContent">
                    This is MMG Home Content<br/>
                    {/* Test Input: <input type="text" name="Test"
                                     style={{borderColor: isPossible ? 'black': 'red'}}
                                     value={phone} onChange={(event) => onPhoneChange(event)}
                    />
                    <br/>
                    <Input
                                            defaultCountry="US"
                                            country="US"
                                            style={{borderColor: isPossible ? 'black' : 'red', borderWidth: isPossible ? 'medium' : ''}}
                                            placeholder="Enter phone number"
                                            value={ phone }
                                            onChange = { (evt) => onPhoneChange2(evt)}
                                            />

                    <br/>
                    Is it possible ? {isPossible ? 'true':'false'}, hope got the answer */}
            </div>
    );
}