import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { capitalize } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Health() {

    const classes = useStyles();

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });


    const [state, setState] = React.useState({
        columns: []
    });

    const [isSubmitted, setIsSubmitted] = React.useState(false);

    const[healthData, setHealthData] = React.useState({
        data: []
    });

    useEffect(() => {
        console.log('Managehealth : useEffect() : start');      
        setIsSubmitted(true);
        fetch(process.env.REACT_APP_API_URL+'/family/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            var owners = {};
            response.forEach(element => {
                owners[element.memberId] = element.firstName;
                console.log(owners);
            });
            
            setState({
                columns : [
                    { title: 'Member', field: 'memberId', lookup: owners },
                    { title: 'Appt Type', field: 'appointmentType', lookup: { 'MEDICAL': 'Medical', 'VISION': 'Vision', 'DENTAL': 'Dental', 'OTHER': 'Other' } },
                    { title: 'Appt Desc', field: 'appointmentDesc', lookup: { 'ANNUAL_PHYSICAL': 'Annual Physical', 'ANNUAL_VISIT': 'Annual Visit', 'DENTAL_REGULAR': 'Dental Regular', 'OTHER': 'Other' }, },
                    { title: 'Appt Date', field: 'appointmentDate', type: 'datetime', format: "MM/dd/yyyy", variant: "inline", margin: "normal" },
                    { title: 'Year', field: 'year' },
                    { title: 'Status', field: 'status', lookup: { 'COMPLETE': 'Complete', 'SCHEDULED': 'Scheduled', 'TO_BE_SCHEDULED': 'To Be Scheduled' } }
                ]
            });

                fetch(process.env.REACT_APP_API_URL+'/health/'+localStorage.getItem('userId'), {
                    method: 'GET',
                    headers : new Headers({
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    })
                })
                .then(res => res.json())
                .then(response => {
                    console.log(response);
                    setHealthData(
                        {
                            data:response
                        }
                    );
                  })
                  .catch(error => console.log(error));
                  setIsSubmitted(false);
            })
          .catch(error => console.log(error));

      }, [userId]);

    const handleClose = () => {
        console.log('GetStarted:handleClose()');
        setIsSubmitted(false);
    };
    

    return (
        <div style={{marginTop: '5%', marginLeft:'2%', marginRight:'2%', marginBottom:'2%', minHeight : '60vh'}}>
        <MaterialTable
            title="Manage Health Calendar"
            localization={{
                body: {
                    emptyDataSourceMessage: 'Add Appointments',
                }
            }}
            options={{
                search: false,
                paging: false,
                toolbarButtonAlignment: "left",
                actionsColumnIndex: -1,
                headerStyle: {
                    textTransform: capitalize,
                    backgroundColor: '#0048BA',
                    color: '#FFF'
                }
            }}
            columns={state.columns}
            data={healthData.data}
            editable={{
                onRowAdd: (newData) =>
                new Promise((resolve) => {
                    resolve();
                    setIsSubmitted(true);
                    console.log('newData');
                    console.log(newData);

                    console.log('JSON.stringify(newData)');
                    console.log(JSON.stringify(newData));

                    newData.userId = localStorage.getItem('userId');
                    fetch(process.env.REACT_APP_API_URL+'/health', {
                        method: 'POST',
                        body: JSON.stringify(newData),
                        headers : new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                            'Content-Type' : 'application/json'
                        })
                    }).then(resp => resp.json())
                        .then(response => {
                            newData.healthId = response.healthId;
                            setHealthData((prevState) => {
                                const data = [...prevState.data];
                                data.push(newData);
                                return { ...prevState, data };
                            });
                            setIsSubmitted(false);
                        });
                }),
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                        resolve();
                        setIsSubmitted(true);
                        console.log('newData');
                        console.log(newData);

                        console.log('JSON.stringify(newData)');
                        console.log(JSON.stringify(newData));

                        newData.userId = localStorage.getItem('userId');
                        fetch(process.env.REACT_APP_API_URL+'/health', {
                            method: 'POST',
                            body: JSON.stringify(newData),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setHealthData((prevState) => {
                                    const data = [...prevState.data];
                                    data[data.indexOf(oldData)] = newData;
                                    return { ...prevState, data };
                                });
                                setIsSubmitted(false);
                            });
                    }),
                onRowDelete: async (oldData) => {
                    console.log('Health be deleted :'+oldData.HealthId);
                    setIsSubmitted(true);
                    let response = await fetch(process.env.REACT_APP_API_URL+'/health/'+oldData.healthId, {
                        method: 'DELETE',
                        headers : new Headers({
                            Authorization: 'Bearer '+localStorage.getItem('token')
                        })
                    });
                    console.log('DELETE home response');
                    console.log(response);
                    if (response.ok) {
                            setHealthData((prevState) => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                            });  
                            setIsSubmitted(false);
                    }
                }
            }}
        />
        <Backdrop className={classes.backdrop} open={isSubmitted} onClick={handleClose}>
                        <CircularProgress htmlColor="royalblue" />
        </Backdrop>
        </div>
    );
}