import React, {useEffect} from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }  

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

function ManageNotifications() {

    const classes = useStyles();

    const [respState, setRespState] = React.useState({
        data : []
    });

    const [saveState , setSaveState] = React.useState({
        status : null
    });

    const [userId, setUserId] = React.useState({
        value : localStorage.getItem('userId')
    });

    const [promoState, setPromoState] = React.useState({
            value : "yes"
    });

    const [policiesState, setPoliciesState] = React.useState({
        value : "yes"
});

    useEffect(() => {
        console.log('ManageNotifications : useEffect() : start');
        fetch(process.env.REACT_APP_API_URL+'/user-preferences/'+localStorage.getItem('userId'), {
            method: 'GET',
            headers : new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
        .then(res => res.json())
        .then(response => {
            console.log('response:');
            console.log(response);
            setRespState(respState => ({
                            data : response
                        }));

            response.forEach(pref => {
                console.log(pref.preferenceType);
                console.log(pref.preferenceValue);
                if(pref.preferenceType === 'PROMOTOTIONS_AND_TIPS') {
                    setPromoState(
                        {
                            value:pref.preferenceValue
                        }
                    );
                }
                else if(pref.preferenceType === 'POLICIES_AND_COMMUNITY') {
                    setPoliciesState(
                        {
                            value:pref.preferenceValue
                        }
                    );
                }
            });
          })
          .catch(error => console.log(error));
      }, [userId]);

    function handleChangePromotions(e){
        console.log('Updating the Preference for Promotions and Tips : '+e.target.value);
        setPromoState({
            value : e.target.value
        });
        updateRespState("PROMOTOTIONS_AND_TIPS", e.target.value);
    };

    function handleChangePollicies(e){
        console.log('Updating the Preference for Pollcies and Community : '+e.target.value);
        setPoliciesState({
            value : e.target.value
        });
        updateRespState("POLICIES_AND_COMMUNITY", e.target.value);
    };

    function updateRespState(type, newValue) {
        console.log('updateRespState : start');
        const tempRespState = respState.data;
        tempRespState.forEach(pref => {
            if(pref.preferenceType === type)
            {
                console.log('Upating Resp for TYPE : '+type);
                pref.preferenceValue = newValue
            }
        });
    
        setRespState({
            data : tempRespState
        });
                
        console.log('updateRespState : End, After Updating RespState to :');
        console.log(respState);
    }

    function savePreferences() {
        console.log('Saving Preferences');
        console.log(promoState);
        console.log(policiesState);
        console.log('Response Before Updating :');
        console.log(respState);
        console.log('Size : '+respState.data.length);
        var request = [];
        if(respState.data.length === 0) {
            request = [
                {
                    "userId": localStorage.getItem('userId'),
                    "preferenceType": "PROMOTOTIONS_AND_TIPS",
                    "preferenceValue": promoState.value
                },
                {
                    "userId": localStorage.getItem('userId'),
                    "preferenceType": "POLICIES_AND_COMMUNITY",
                    "preferenceValue": policiesState.value
                }
            ]
        }
        else {
            request = respState.data;
        }
        console.log('Request before updating :');
        console.log(request);
        fetch(process.env.REACT_APP_API_URL+'/user-preferences', {
                            method: 'POST',
                            body: JSON.stringify(request),
                            headers : new Headers({
                                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                'Content-Type' : 'application/json'
                            })
                        }).then(resp => resp.json())
                            .then(response => {
                                setRespState({
                                    data : response
                                });
                                setSaveState({
                                    status : "SUCCESS"
                                });
                            })
                            .catch(error => {
                                console.log(error);
                                setSaveState({
                                    status : "FAILED"
                                });
                            });;

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSaveState({
            status : null
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                
                <Grid
            container
            direction="row"
            justify="flex-justify"
            alignItems="center"
        >
            <FormControl component="fieldset" color="primary">
                <FormLabel component="notification"> 
                <h2>Email Notifications from Manage My Globe</h2></FormLabel>
                {saveState.status === 'SUCCESS' ? 
                        <Snackbar open={saveState.status === 'SUCCESS'} 
                                  autoHideDuration={3000} onClose={handleClose}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity="success">
                          Successfully saved!
                        </Alert>
                      </Snackbar> : (saveState.status === 'FAILED' ? <h4>FAILED</h4> : '') }
                <RadioGroup row aria-label="promotion" name="promotion"
                            value={promoState.value} 
                            onChange={handleChangePromotions}>
                    <Grid item xs={12} sm={6}>
                        <h3>Promotions and Tips </h3>

                        <FormControlLabel
                            value="yes"
                            control={<Radio color="primary" size='small' />}
                            label="Yes"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio color="primary" size='small' />}
                            label="No"
                            labelPlacement="end"
                        />
                    </Grid>
                </RadioGroup>

                <RadioGroup row aria-label="policy" name="policy"
                            value={policiesState.value}
                            onChange={handleChangePollicies}>
                <Grid item xs={12} sm={6}>
                    <h3>Policies & Community</h3>

                    <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" size='small' />}
                        label="Yes"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio color="primary" size='small' />}
                        label="No"
                        labelPlacement="end"
                    />
                </Grid>
                </RadioGroup>

                <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={savePreferences}
                    >
                        Save
                    </Button>

            </FormControl >
        </Grid>

            </div>
        </Container>
    );
}

export default ManageNotifications;