import axios from 'axios';

class Auth {
    constructor() {
        this.isAuthenticated = false;
    }

    handleLogin(props, formData) {
        console.log('Auth:handleLogin()');
        console.log('Auth:Email : '+formData.email + ' Password : '+formData.password);
        return axios.post(process.env.REACT_APP_API_URL+'/authenticate', formData)
                .then(resp => {
                        console.log('Received resp from Login API : Data ==> ');
                        let tokenResp = resp.data;
                        console.log(tokenResp.token);
                        var jwtDecode = require('jwt-decode');
                        var userDetails = jwtDecode(tokenResp.token);
                        console.log('userDetails :');
                        console.log(userDetails);
                        console.log('isVerified==>'+userDetails.isVerified);
                        if(userDetails.sub === formData.email && userDetails.isVerified === 'true') {
                            this.isAuthenticated = true;
                            localStorage.setItem('login_status', true);
                            localStorage.setItem('token', tokenResp.token);
                            localStorage.setItem('userId', userDetails.userId);
                            localStorage.setItem('firstName', userDetails.firstName);
                            localStorage.setItem('email', userDetails.sub);
                            localStorage.setItem('isVerified', userDetails.isVerified);
                            localStorage.setItem('imageId', userDetails.imageId);
                            console.log('Profile ImageId : '+localStorage.getItem('imageId'));
                            props.history.push('/home');
                            return userDetails;    
                        }
                        else if(userDetails.isVerified === 'false') {
                            console.log('User Email Address is not yet verified!');
                            return "Your email address is not verified, please check your email for verification link that was sent during registration, confirm by following instruction in the email, and then try signing in again.";
                        }
                    })
                    .catch(error => {
                        console.log('error');
                        console.log(error);
                        console.log('errorKey==>'+error.response.data.errorKey);
                        console.log('errorMessage==>'+error.response.data.errorMessage);
                        return error.response.data.errorMessage;
                    });
    }

    handleRegistration(props, formData) {
        console.log('Auth:handleRegistration()');
        console.log('Auth:formValues : ');
        console.log(formData);

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');
        headers.append('GET', 'POST', 'OPTIONS');

        return axios.post(process.env.REACT_APP_API_URL+'/registration', formData, { headers : headers })
                .then(resp => {
                            console.log('Received resp from Login API : Data ==> ');
                            console.log(resp);
                            let userDetails = resp.data;
                            console.log(userDetails);
                            console.log('Email from response :'+ userDetails.email);
                            if(userDetails.email === formData.email) {
                                this.isAuthenticated = true;
                                localStorage.setItem('login_status', true);
                                //props.history.push('/home');
                                return "SUCCESS";
                            }
                        })
                    .catch(error => {
                        console.log('error');
                        console.log(error);
                        console.log('errorKey==>'+error.response.data.errorKey);
                        console.log('errorMessage==>'+error.response.data.errorMessage);
                        return error.response.data.errorMessage;
                    });
    }

    handleLogout() {
        console.log('Auth:handleLogout()');
        this.isAuthenticated = false;
        localStorage.clear();
    }

    isLoggedIn() {
        console.log('Auth:isLoggedIn()');
        if(localStorage.getItem('login_status'))
            return true;
        else
            return false;
    }
}

export default new Auth();