import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import { capitalize} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

export default function ManageReminders() {

    const [userId, setUserId] = React.useState({
        value: localStorage.getItem('userId')
    });

    const handleEmailChange = (rowData) => {
        console.log('handleEmailChange:start');
        console.log('rowData, current value==>'+JSON.stringify(rowData));
        console.log('emailRequired, current value==>'+rowData.emailRequired);

        if(rowData.emailRequired){
            console.log('Changing to false');
            rowData.emailRequired = false;
        }
        else if(!rowData.emailRequired) {
            console.log('Changing to true');
            rowData.emailRequired = true;
        }
        console.log('emailRequired, after changing, before committing==>'+rowData.emailRequired);
        saveChange(rowData);
    };

    const handleTextChange = (rowData) => {
        console.log('handleTextChange:start');
        console.log('rowData, current value==>'+JSON.stringify(rowData));
        console.log('textRequired, current value==>'+rowData.textRequired);

        if(rowData.textRequired){
            console.log('Changing to false');
            rowData.textRequired = false;
        }
        else if(!rowData.textRequired) {
            console.log('Changing to true');
            rowData.textRequired = true;
        }
        console.log('textRequired, after changing, before committing==>'+rowData.textRequired);
        saveChange(rowData);
    };
       

    const saveChange = (rowData) => {
        rowData.userId = localStorage.getItem('userId');
        fetch(process.env.REACT_APP_API_URL+'/reminder-setting', {
            method: 'POST',
            body: JSON.stringify(rowData),
            headers : new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type' : 'application/json'
            })
        }).then(resp => resp.json())
            .then(response => {
            setReminderData((prevState) => {
                const data = [...prevState.data];
                data[data.indexOf(rowData)] = rowData;
                return { ...prevState, data };
            });
            })
        .catch(error => {
            console.log("Error: "+error);
        });
    }

    const [state, setState] = React.useState({
        columns: [
            { title: 'Reminder', field: 'reminderFor',
                 lookup: { 'DOCUMENTS': 'Documents', 'SERVICES': 'Services', 'SUBSCRIPTIONS': 'Subscriptions', 'TASKS': 'Tasks', 'HEALTH': 'Health' }, 
                 editable: 'never'    
            },
            { title: 'Email', field: 'emailRequired', editable: 'never',
                render: rowData => <Switch
                                        checked={rowData.emailRequired === true}
                                        onChange={() => handleEmailChange(rowData)}
                                        name="checkedA"
                                        color="primary"
                                    /> 
            },
            { title: 'Text', field: 'textRequired', editable: 'never',
                render: rowData => <Switch
                                        checked={rowData.textRequired === true}
                                        onChange={() => handleTextChange(rowData)}
                                        name="checkedB"
                                        color="primary"
                                    />
            },
            { title: 'Days to Remind', field: 'daysToRemind',
                validate: rowData => rowData && rowData.daysToRemind && (isNaN(rowData.daysToRemind) || (rowData.daysToRemind < 0 || rowData.daysToRemind > 60)) ? 
                {isValid : false, helperText: 'Days should be a number between 0 and 60'} : {isValid:true, helperText:''},  },
        ]
    });

    const [reminderData, setReminderData] = React.useState({
        data: []
    });

    useEffect(() => {

        console.log('Managereminderes : useEffect() : start');

        fetch(process.env.REACT_APP_API_URL + '/reminder-setting/' + localStorage.getItem('userId'), {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token')
            })
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                setReminderData(
                    {
                        data: response
                    }
                );
            })
            .catch(error => console.log(error));
    }, [userId]);


    return (
        <div style={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%', minHeight: '60vh' }}>
            <MaterialTable
                title="Manage Reminders"
                localization={{
                    body: {
                        emptyDataSourceMessage: 'Manage Reminders',
                    }
                }}
                options={{
                    search: false,
                    paging: false,
                    toolbarButtonAlignment: "left",
                    actionsColumnIndex: -1,
                    headerStyle: {
                        textTransform: capitalize,
                        backgroundColor: '#0048BA',
                        color: '#FFF'
                    }
                }}
                columns={state.columns}
                data={reminderData.data}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                            resolve();
                            console.log('newData');
                            console.log(newData);

                            console.log('JSON.stringify(newData)');
                            console.log(JSON.stringify(newData));

                            newData.userId = localStorage.getItem('userId');
                            fetch(process.env.REACT_APP_API_URL + '/reminder-setting', {
                                method: 'POST',
                                body: JSON.stringify(newData),
                                headers: new Headers({
                                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                                    'Content-Type': 'application/json'
                                })
                            }).then(resp => resp.json())
                                .then(response => {
                                    setReminderData((prevState) => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                });
                        })
                }}
            />
        </div>
    );
}