import React, {useState} from 'react';
import './App.css';
import HomeHeader from './components/HomeHeader/HomeHeader';
import HomeFooter from './components/HomeFooter/HomeFooter';
import HomeContent from './components/HomeContent/HomeContent';
import Login from './components/Login/Login';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import GetStarted from './components/GetStarted/GetStarted';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import Settings from './components/Settings/Settings';
import Expenses from './components/Core/Expenses/Expenses';
import Documents from './components/Core/Documents/Documents';
import CheckList from './components/Core/CheckList/CheckList';
import Wishes from './components/Core/Wishes/Wishes';
import Health from './components/Core/Health/Health';
import Contacts from './components/Core/Contacts/Contacts';
import Home from './components/Home/Home';
import HealthVitals from './components/Core/Health/HealthVitals';
import UserVerification from './components/UserVerification/UserVerification';
import Reminders from './components/Core/CheckList/Reminders';
import Subscriptions from './components/Core/Services/Subscriptions';
import Tasks from './components/Core/Services/Tasks';
import ForgotPasswordVerification from './components/ForgotPassword/ForgotPasswordVerification';

function App(props) {

  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [userDetails, setUserDetails] = useState({});
  const [profileImage, setProfileImage] = React.useState('null');

  function handleLogin(userData) {
    console.log('App:handleLogin()');
    setIsLoggedIn(true);
    if(localStorage.getItem('imageId') && localStorage.getItem('imageId') !== '') {
      console.log("IF part of Profile Image");
      fetch(process.env.REACT_APP_API_URL+'/image/'+localStorage.getItem('imageId'), {
          method : 'GET',
          headers: new Headers({ 
                                  'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                              })
      })
      .then(resp => resp.text())
      .then(data => {
          console.log('Getting Profile image');
          setProfileImage('data:image/jpeg;base64,'+data); 
      });
  }
    setUserDetails(userData);
  };

  function handleLogout(props) {
    console.log('App:handleLogout()');
    setIsLoggedIn(false);
    //props.history.push("/");
  };

  function navigateToSettings(){
    console.log('App:navigateToSettings()');
    props.history.push("/settings");
  }

  return (
    <div className="App">
      <BrowserRouter>
        <HomeHeader {...props} 
                      isLoggedIn={isLoggedIn} 
                        handleLogout={handleLogout}
                          userDetails={userDetails}
                            profileImage={profileImage}
                           navigateToSettings={navigateToSettings}/>
          <Switch>
            <Route path="/" exact component={HomeContent}/>
            <Route path="/login" render={(props) =>  
                                          <Login {...props} handleLogin={handleLogin}/>
                                        }/>
            <Route path="/start" render={(props) =>  
                                          <GetStarted {...props} handleLogin={handleLogin}/>
                                        }/>
            <ProtectedRoute path="/settings" component={Settings}/>
            <ProtectedRoute path="/home" component={Home}/>
            <ProtectedRoute path="/expenses" component={Expenses}/>
            <ProtectedRoute path="/documents" component={Documents}/>
            <ProtectedRoute path="/subscriptions" component={Subscriptions}/>
            <ProtectedRoute path="/tasks" component={Tasks}/>
            <ProtectedRoute path="/checklist" component={CheckList}/>
            <ProtectedRoute path="/reminders" component={Reminders}/>
            <ProtectedRoute path="/wishes" component={Wishes}/>
            <ProtectedRoute path="/health" component={Health}/>
            <ProtectedRoute path="/health-vitals" component={HealthVitals}/>
            <ProtectedRoute path="/contacts" component={Contacts}/>
            <Route path="/user-verification" component={UserVerification}/>
            <Route path="/forgot_password" component={ForgotPassword}/>
            <Route path="/forgot-pwd-verification" component={ForgotPasswordVerification}/>
            <ProtectedRoute path="*" component={HomeContent}/>
          </Switch>
        <HomeFooter/>
      </BrowserRouter>
    </div>
  );
}

export default App;

